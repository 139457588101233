import { useEffect, useMemo, type MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import BusinessIcon from '@mui/icons-material/Business'
import VerifiedIcon from '@mui/icons-material/Verified'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

import { type PortalSetting } from 'types'
import {
  InfoText,
  MainHeader,
  SubContentWrapper,
  SubHeader,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { Path, PortalType } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import LogoWrapper from 'components/LogoWrapper'
import { casesDataState } from 'state/caseListStates'
import { userAccessState } from 'state/userStates'

const PortalWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .sub-content {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

const VirtualWrapper = styled(Box)<{ virtual: string }>`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 5px;
  top: 0;
  left: 0;
  background-color: ${({ virtual, theme }) =>
    virtual ? theme.palette.warning.main : theme.palette.success.main};
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & svg {
    color: ${({ theme }) => theme.palette.background.paper};
  }
`

const PortalListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { data: portalsData, isLoading } = useSWR<PortalSetting[]>(
    `${process.env.REACT_APP_API_PATH ?? ''}/portals`,
  )
  const { goTo } = useRoute()
  const setPortalSetting = useSetRecoilState(portalSettingState)
  const userAccess = useRecoilValue(userAccessState)
  const setCasesData = useSetRecoilState(casesDataState)

  useEffect(() => {
    setCasesData([])
    setPortalSetting(null)
  }, [])

  const handleEditPortal = (
    event: MouseEvent<HTMLButtonElement>,
    portalId: string,
  ): void => {
    event.stopPropagation()
    goTo(`${Path.PORTALS_EDIT}/${portalId}`)
  }

  const handleGoToPortal = (portalId: string): void => {
    goTo(`${Path.ROOT}?portalId=${portalId}`)
  }

  const handleGoToConsumer = (
    event: MouseEvent<HTMLButtonElement>,
    portalId: string,
  ): void => {
    event.stopPropagation()
    goTo(`${Path.PUBLIC_PAGE}?portalId=${portalId}`)
  }

  const filteredPortals = useMemo(() => {
    if (!userAccess) {
      return []
    }

    const sortedData = portalsData?.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      return 0
    })

    if (userAccess.userData.admin) {
      return sortedData
    }

    const validPortalIds = userAccess.accessiblePortals.map(
      (accessiblePortal) => accessiblePortal.portalId,
    )

    return sortedData?.filter((portal) => validPortalIds.includes(portal.id))
  }, [portalsData, userAccess])

  return (
    <Stack height="100%" width="100%" spacing={2}>
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>{formatMessage({ id: 'portal_list.header' })}</MainHeader>
        </Box>

        {userAccess?.userData.admin && (
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            data-testid="portal-add-button"
            onClick={() => {
              goTo(Path.PORTALS_ADD)
            }}
            color="secondary"
          >
            {formatMessage({ id: 'portal_list.button.create_portal' })}
          </Button>
        )}
      </Stack>

      {isLoading && <CircularProgress />}

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {filteredPortals?.map((portal) => (
          <PortalWrapper
            key={portal.id}
            direction="row"
            spacing={2}
            paddingX={4}
            paddingY={2}
            data-testid="portal-info"
            onClick={(event) => {
              handleGoToPortal(portal.id)
            }}
          >
            <VirtualWrapper virtual={portal.virtual ? 'virtual' : ''}>
              {portal.virtual && (
                <EmailIcon data-testid="virtual-icon" fontSize="small" />
              )}
              {!portal.virtual && (
                <VerifiedIcon data-testid="non-virtual-icon" fontSize="small" />
              )}
            </VirtualWrapper>
            <LogoWrapper size={120} url={portal.logoUrl} alt={portal.name}>
              {!portal.logoUrl && portal.type === PortalType.CITY && (
                <LocationCityIcon fontSize="small" sx={{ fontSize: 80 }} />
              )}

              {!portal.logoUrl && portal.type === PortalType.PROPERTY && (
                <BusinessIcon fontSize="small" sx={{ fontSize: 80 }} />
              )}
            </LogoWrapper>

            <Stack justifyContent="center" spacing={1} flex={1}>
              <SubHeader>{portal.name}</SubHeader>
              <InfoText>{portal.businessId}</InfoText>
              <Stack direction="row" spacing={1}>
                {userAccess?.userData.admin && (
                  <Button
                    variant="outlined"
                    size="small"
                    data-testid="portal-setting-button"
                    onClick={(event) => {
                      handleEditPortal(event, portal.id)
                    }}
                  >
                    {formatMessage({ id: 'portal_setting.header' })}
                  </Button>
                )}

                {userAccess?.userData.admin && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={(event) => {
                      handleGoToConsumer(event, portal.id)
                    }}
                  >
                    {formatMessage({ id: 'portal_list.button.go_to_consumer' })}
                  </Button>
                )}
              </Stack>
            </Stack>

            {(!!portal.address || portal.email || portal.phone) && (
              <SubContentWrapper spacing={1} flex={1}>
                {portal.address && (
                  <Stack direction="row" spacing={1}>
                    <LocationOnIcon fontSize="small" />
                    <address>
                      <Typography variant="body2">{portal.address}</Typography>
                    </address>
                  </Stack>
                )}

                {portal.email && (
                  <Stack direction="row" spacing={1}>
                    <EmailIcon fontSize="small" />
                    <Typography variant="body2">{portal.email}</Typography>
                  </Stack>
                )}

                {portal.phone && (
                  <Stack direction="row" spacing={1}>
                    <LocalPhoneIcon fontSize="small" />
                    <Typography variant="body2">{portal.phone}</Typography>
                  </Stack>
                )}
              </SubContentWrapper>
            )}
          </PortalWrapper>
        ))}
      </Stack>
    </Stack>
  )
}

export default PortalListPage
