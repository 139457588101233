import styled from '@mui/material/styles/styled'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export const ExpandIcon = styled(ChevronRightIcon)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 50%;
`

export const CollapseIcon = styled(ExpandMoreIcon)`
  background: ${({ theme }) => theme.palette.info.light};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 50%;
`

export const TreeView = styled(SimpleTreeView)`
  &,
  .MuiTreeItem-root,
  .MuiCollapse-vertical {
    display: flex;
    flex-direction: column;
  }

  gap: 10px;

  > .MuiTreeItem-root {
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.divider};
    border-radius: 8px;
  }

  .MuiTreeItem-root {
    padding: 8px;
  }

  .MuiCollapse-root .MuiTreeItem-root {
    padding-left: 10px;
    border-left: 1px solid;
    border-left-color: ${({ theme }) => theme.palette.divider};
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.palette.divider};
    border-bottom-left-radius: 8px;
    margin-top: -4px;
  }

  .MuiTreeItem-content .MuiTreeItem-iconContainer svg {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
  }

  .MuiCollapse-root {
    .MuiTreeItem-content {
      padding-top: 8px;
      padding-bottom: 4px;
    }
  }

  .MuiTreeItem-content {
    gap: 10px;
    padding-top: 8px;
    padding-bottom: 4px;

    &.Mui-selected,
    &.Mui-focused {
      background-color: transparent;
    }
  }

  .MuiTreeItem-root .MuiTreeItem-content.Mui-selected,
  .MuiTreeItem-root .MuiTreeItem-content.Mui-focused {
    background-color: transparent;
  }
`

export const FilterTreeView = styled(SimpleTreeView)`
  &,
  .MuiTreeItem-root,
  .MuiCollapse-vertical {
    display: flex;
    flex-direction: column;
  }

  .MuiTreeItem-root {
    padding: 2px 4px;
  }

  .MuiTreeItem-content .MuiTreeItem-iconContainer svg {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
  }

  .MuiTreeItem-root .MuiTreeItem-content.Mui-selected,
  .MuiTreeItem-root .MuiTreeItem-content.Mui-focused {
    background-color: transparent;
  }
`
