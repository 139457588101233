import { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR, { useSWRConfig } from 'swr'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import BadgeIcon from '@mui/icons-material/Badge'
import ApartmentIcon from '@mui/icons-material/Apartment'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import PinIcon from '@mui/icons-material/Pin'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import VerifiedIcon from '@mui/icons-material/Verified'

import {
  LightSubHeader,
  SubSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import {
  ADDRESS_MAX_LENGTH,
  PORTAL_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  BUSINESS_ID_MAX_LENGTH,
  PortalType,
  SUPPORT_LOCALE_LABEL,
  SUPPORT_DATE_FORMATS,
  SUPPORT_REGION_LABEL,
  SUPPORT_REGIONS,
  Path,
} from '../commonConstants'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import useApi from 'hooks/useApi'
import {
  type PortalSetting,
  type NewPortalSetting,
  type PortalInfo,
} from 'types'
import useRoute from 'hooks/useNavigate'
import { portalSettingState } from 'state/portalSettingStates'
import {
  hasNewFileState,
  isFileUploadingState,
  hasUnsavedChangesState,
  processedFormFilesState,
} from 'state/formStates'
import ImageUploader from 'components/form/ImageUploader'
import AddressInputWithMap from 'components/form/AddressInputWithMap'
import {
  currentZoomState,
  selectedLocationInfoState,
  hasNewAddressState,
} from 'state/mapStates'
import { userAccessState } from 'state/userStates'
import AddressInput from 'components/form/AddressInput'

type SettingFormFields = {
  name: string
  logoUrl?: string
  type: PortalType | ''
  businessId: string
  address: string
  invoiceAddress: string
  phone: string
  email: string
  dateFormat: string
  defaultLanguage: string
  supportLanguages: string[]
  portalInfo: PortalInfo
  virtual: boolean
  mapConfiguration: {
    region: string
    zoomLevel: number
    centre: google.maps.LatLngLiteral
  }
}

const PreferencesWrapper = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  & .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`

const ReportSwitch = styled(RadioGroup)`
  display: flex;
  width: 100%;
`

const ReportMethod = styled(FormControlLabel)<{ selected?: string }>`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(1)};
  align-items: center;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '1.5px solid #07208D' : 'none')};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 0px 3px #bbc6f1' : 'none'};
  z-index: ${({ selected }) => (selected ? 1 : 0)};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  margin: 0px;
`

// const ProfileFieldsWrapper = styled(Box)`
//   background: white;
//   border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
//   padding: ${({ theme }) => theme.spacing(2)};
// `

// const ProfileFieldGroup = styled(ToggleButtonGroup)`
//   gap: ${({ theme }) => theme.spacing(2)};

//   & .MuiToggleButtonGroup-grouped {
//     font-size: 12px;
//     border: 0;
//     padding: 2px 12px;

//     &.Mui-disabled {
//       border: 0;
//     }

//     &.Mui-selected {
//       border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
//       background-color: ${({ theme }) => theme.palette.primary.main};
//       color: white;
//     }
//   }
// `
const FORM_NAME = 'portal'
const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 120
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 216

const PortalEditPage: React.FC = () => {
  const { formatMessage } = useIntl()

  const { goTo } = useRoute()
  const { portalId } = useParams()
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const userAccess = useRecoilValue(userAccessState)
  const [isSaving, setIsSaving] = useState(false)
  const [portalSetting, setPortalSetting] = useRecoilState(portalSettingState)
  const selectedLocationInfo = useRecoilValue(
    selectedLocationInfoState(FORM_NAME),
  )
  const hasNewAddress = useRecoilValue(hasNewAddressState(FORM_NAME))
  const currentZoom = useRecoilValue(currentZoomState)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { data: portalSettingData, isLoading } = useSWR<PortalSetting>(
    portalId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${portalId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
  } = useForm<SettingFormFields>({
    mode: 'onTouched',
  })
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const hasNewFile = useRecoilValue(hasNewFileState)
  const { cache, mutate } = useSWRConfig()
  const logoUrl = watch('logoUrl')

  useEffect(() => {
    if (portalSettingData) {
      setValue('type', portalSettingData.type)
      setValue('name', portalSettingData.name)
      setValue('businessId', portalSettingData.businessId)
      setValue('address', portalSettingData.address ?? '')
      setValue('invoiceAddress', portalSettingData.invoiceAddress ?? '')
      setValue('phone', portalSettingData.phone ?? '')
      setValue('email', portalSettingData.email ?? '')
      setValue('dateFormat', portalSettingData.dateFormat)
      setValue('defaultLanguage', portalSettingData.defaultLanguage)
      setValue('supportLanguages', portalSettingData.supportedLanguages)

      if (portalSettingData.portalInfo?.customerProfile) {
        setValue('portalInfo.customerProfile', {
          ...getValues('portalInfo.customerProfile'),
          ...portalSettingData.portalInfo.customerProfile,
        })
      }

      setValue('virtual', portalSettingData.virtual === true)
      if (portalSettingData.mapConfiguration) {
        setValue('mapConfiguration', portalSettingData.mapConfiguration)
      }

      if (portalSettingData.logoUrl) {
        setValue('logoUrl', portalSettingData.logoUrl)
      }
    }
  }, [portalSettingData])

  useEffect(() => {
    setValue('logoUrl', processedFormFiles?.[0]?.url)
  }, [processedFormFiles])

  const onSubmit: SubmitHandler<SettingFormFields> = useCallback(
    async (data): Promise<void> => {
      if (data.type && selectedLocationInfo) {
        try {
          setIsSaving(true)
          const formData: NewPortalSetting = {
            name: data.name,
            type: data.type,
            businessId: data.businessId,
            address: data.address,
            invoiceAddress: data.invoiceAddress,
            phone: data.phone,
            email: data.email,
            dateFormat: data.dateFormat,
            defaultLanguage: data.defaultLanguage,
            supportedLanguages: data.supportLanguages,
            portalInfo: data.portalInfo,
            virtual: data.virtual,
            mapConfiguration: {
              region: data.mapConfiguration.region,
              zoomLevel: currentZoom,
              centre: selectedLocationInfo.position,
            },
            logoUrl: data.logoUrl,
          }

          if (portalSettingData) {
            const response = await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSettingData.id
              }`,
              formData,
            )

            const newPortalSettingData = await response.json()

            const newPortalSetting = {
              ...newPortalSettingData,
              portalInfo: {
                customerProfile: {
                  email: 'required',
                  name: 'optional',
                  phone: 'hidden',
                  address: 'hidden',
                  customerId: 'hidden',
                  company: 'hidden',
                  building: 'hidden',
                  section: 'hidden',
                  floor: 'hidden',
                  room: 'hidden',
                },
              },
            } as unknown as PortalSetting

            const cachedPortals = cache.get(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals`,
            )?.data as PortalSetting[]
            if (cachedPortals) {
              const updatedPortals = cachedPortals.filter(
                (portal) => portal.id !== newPortalSetting.id,
              )
              updatedPortals.push(newPortalSetting)

              await mutate(
                `${process.env.REACT_APP_API_PATH ?? ''}/portals`,
                updatedPortals,
              )

              await mutate(
                `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                  portalSettingData.id
                }`,
                newPortalSetting,
              )
            }

            if (portalSetting?.id === portalSettingData.id) {
              setPortalSetting(newPortalSetting)
            }
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          if (portalSetting?.id !== portalSettingData?.id || !portalId) {
            goTo(Path.PORTALS_LIST, true)
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [
      portalSettingData,
      currentZoom,
      selectedLocationInfo,
      portalSetting,
      portalId,
    ],
  )

  const handleGoBack = (): void => {
    goTo(Path.PORTALS_LIST)
  }

  const hasUnsavedChanges = useMemo(
    () => hasNewFile || isDirty,
    [hasNewFile, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasNewFile)
  }, [hasUnsavedChanges])

  const shouldDisableSaving = useMemo(
    () =>
      (!isValid ||
        Object.keys(errors).length > 0 ||
        !selectedLocationInfo ||
        isFileUploading ||
        !logoUrl ||
        isSaving) &&
      !hasNewFile &&
      !hasNewAddress,
    [
      isValid,
      errors,
      isFileUploading,
      isSaving,
      hasNewFile,
      selectedLocationInfo,
      hasNewAddress,
      logoUrl,
    ],
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              {!portalSetting && (
                <IconButton
                  onClick={handleGoBack}
                  size="small"
                  aria-label={formatMessage({
                    id: 'general.icon_button.go_back',
                  })}
                  color="primary"
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )}

              <MainHeader>
                {formatMessage({ id: 'portal_edit.header' })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={isSaving}
              disabled={shouldDisableSaving}
              data-testid="portal-save-button"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack
              paddingY={2}
              paddingX={4}
              spacing={2}
              data-testid="company-information"
            >
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_edit.subheader.company_information',
                })}
              </LightSubHeader>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="220px" height="220px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                    defaultImageUrl={portalSettingData?.logoUrl}
                  />

                  {!logoUrl && isDirty && (
                    <FormHelperText error>
                      {formatMessage({
                        id: 'general.error.required',
                      })}
                    </FormHelperText>
                  )}
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <FormControl error={!!errors.type}>
                    <InputLabel id="setting-portal-type-label">
                      {formatMessage({
                        id: 'portal_edit.label.portal_type',
                      })}
                    </InputLabel>
                    <Controller
                      name="type"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          disabled={!userAccess?.userData.admin}
                          labelId="setting-portal-type-label"
                          label={formatMessage({
                            id: 'portal_edit.label.portal_type',
                          })}
                          startAdornment={
                            <InputAdornment position="start">
                              <ApartmentIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          }
                          size="small"
                          fullWidth
                          variant="outlined"
                        >
                          <MenuItem
                            key={PortalType.CITY}
                            value={PortalType.CITY}
                          >
                            {formatMessage({
                              id: 'portal_edit.portal_type.city',
                            })}
                          </MenuItem>
                          <MenuItem
                            key={PortalType.PROPERTY}
                            value={PortalType.PROPERTY}
                          >
                            {formatMessage({
                              id: 'portal_edit.portal_type.property',
                            })}
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.name}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: PORTAL_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.name}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'portal_edit.label.portal_name',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.name?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'portal_edit.error.max_length_portal_name',
                          },
                          {
                            max: PORTAL_NAME_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}

                    {errors.name?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'portal_edit.error.required_portal_name',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.businessId}>
                    <Controller
                      name="businessId"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: BUSINESS_ID_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.name}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'portal_edit.label.business_id',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PinIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.businessId?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: BUSINESS_ID_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}

                    {errors.businessId?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.phone}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        maxLength: PHONE_MAX_LENGTH,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidPhoneNumber(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={formatMessage({
                            id: 'portal_edit.label.phone',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.phone?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_phone',
                        })}
                      </FormHelperText>
                    )}
                    {errors.phone?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_phone',
                          },
                          { max: PHONE_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.email}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        maxLength: EMAIL_MAX_LENGTH,
                        required: true,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidEmail(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'portal_edit.label.email',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.email?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_email',
                        })}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_email',
                          },
                          { max: EMAIL_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.address}>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        maxLength: ADDRESS_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <AddressInput
                          {...field}
                          region={portalSettingData?.mapConfiguration?.region}
                          language={portalSetting?.defaultLanguage.toLowerCase()}
                          startAdornment={
                            <InputAdornment position="end">
                              <LocationOnIcon
                                fontSize="small"
                                color="primary"
                              />
                            </InputAdornment>
                          }
                        />
                      )}
                    />

                    {errors.address?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: ADDRESS_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.invoiceAddress}>
                    <Controller
                      name="invoiceAddress"
                      control={control}
                      rules={{
                        maxLength: ADDRESS_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <AddressInput
                          {...field}
                          region={portalSettingData?.mapConfiguration?.region}
                          language={portalSetting?.defaultLanguage.toLowerCase()}
                          label={formatMessage({
                            id: 'portal_edit.label.invoice_address',
                          })}
                          startAdornment={
                            <InputAdornment position="end">
                              <BusinessCenterIcon
                                fontSize="small"
                                color="primary"
                              />
                            </InputAdornment>
                          }
                        />
                      )}
                    />

                    {errors.invoiceAddress?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: ADDRESS_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Stack
              paddingY={2}
              paddingX={4}
              spacing={2}
              width={'100%'}
              data-testid="portal-preferences"
            >
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_edit.subheader.portal_preferences',
                })}
              </LightSubHeader>

              <PreferencesWrapper
                padding={2}
                width={'100%'}
                direction="row"
                spacing={2}
              >
                <Stack spacing={2} width={'50%'}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="setting-default-language-label"
                      size="small"
                      required
                    >
                      {formatMessage({
                        id: 'portal_edit.label.default_language',
                      })}
                    </InputLabel>
                    <Controller
                      name="defaultLanguage"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          labelId="setting-default-language-label"
                          label={formatMessage({
                            id: 'portal_edit.label.default_language',
                          })}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(event): void => {
                            const selectedValue = event.target.value
                            const selectedSupportLanguages =
                              getValues('supportLanguages')
                            if (
                              !selectedSupportLanguages.includes(selectedValue)
                            ) {
                              setValue('supportLanguages', [
                                ...selectedSupportLanguages,
                                selectedValue,
                              ])
                            }
                            field.onChange(event)
                          }}
                        >
                          {Object.keys(SUPPORT_LOCALE_LABEL).map((localKey) => (
                            <MenuItem key={localKey} value={localKey}>
                              {SUPPORT_LOCALE_LABEL[localKey]}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>
                      {formatMessage({
                        id: 'portal_edit.helper_text.default_language',
                      })}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel
                      id="setting-date-format-label"
                      size="small"
                      required
                    >
                      {formatMessage({
                        id: 'portal_edit.label.date_format',
                      })}
                    </InputLabel>
                    <Controller
                      name="dateFormat"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          labelId="setting-date-format-label"
                          label={formatMessage({
                            id: 'portal_edit.label.date_format',
                          })}
                          size="small"
                          fullWidth
                          variant="outlined"
                        >
                          {SUPPORT_DATE_FORMATS.map((dateFormat) => (
                            <MenuItem key={dateFormat} value={dateFormat}>
                              {dateFormat}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>
                      {formatMessage({
                        id: 'portal_edit.helper_text.date_format',
                      })}
                    </FormHelperText>
                  </FormControl>
                </Stack>

                <Stack width={'50%'}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="setting-locales-label"
                      size="small"
                      required
                    >
                      {formatMessage({
                        id: 'portal_edit.label.locales',
                      })}
                    </InputLabel>
                    <Controller
                      name="supportLanguages"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          multiple
                          labelId="setting-locales-label"
                          label={formatMessage({
                            id: 'portal_edit.label.locales',
                          })}
                          size="small"
                          fullWidth
                          input={
                            <OutlinedInput
                              id="setting-locales-chip"
                              label={formatMessage({
                                id: 'portal_edit.label.locales',
                              })}
                            />
                          }
                          onChange={(event): void => {
                            const newValue = event.target.value
                            const defaultLanguage = getValues('defaultLanguage')

                            if (
                              typeof newValue === 'string' &&
                              newValue !== defaultLanguage
                            ) {
                              setValue('supportLanguages', [
                                newValue,
                                defaultLanguage,
                              ])
                            }

                            if (
                              Array.isArray(newValue) &&
                              !newValue.includes(defaultLanguage)
                            ) {
                              console.log([...newValue, defaultLanguage])
                              setValue('supportLanguages', [
                                ...newValue,
                                defaultLanguage,
                              ])
                            }

                            field.onChange(event)
                          }}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  size="small"
                                  label={SUPPORT_LOCALE_LABEL[value]}
                                />
                              ))}
                            </Box>
                          )}
                        >
                          {Object.keys(SUPPORT_LOCALE_LABEL).map((localKey) => (
                            <MenuItem key={localKey} value={localKey}>
                              <Checkbox
                                checked={
                                  getValues('supportLanguages')?.includes(
                                    localKey,
                                  ) || localKey === getValues('defaultLanguage')
                                }
                              />
                              <ListItemText
                                primary={SUPPORT_LOCALE_LABEL[localKey]}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <FormHelperText>
                      {formatMessage({
                        id: 'portal_edit.helper_text.locales',
                      })}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </PreferencesWrapper>

              <SubSubHeader>
                {formatMessage({
                  id: 'portal_edit.subheader.default_map_view',
                })}
              </SubSubHeader>
              <PreferencesWrapper padding={2} width={'100%'} spacing={2}>
                <FormControl fullWidth>
                  <InputLabel
                    id="setting-map-region-label"
                    size="small"
                    required
                  >
                    {formatMessage({
                      id: 'portal_edit.label.map_region',
                    })}
                  </InputLabel>
                  <Controller
                    name="mapConfiguration.region"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <Select
                        {...field}
                        required
                        labelId="setting-map-region-label"
                        label={formatMessage({
                          id: 'portal_edit.label.map_region',
                        })}
                        size="small"
                        fullWidth
                        variant="outlined"
                      >
                        {SUPPORT_REGIONS.map((region) => (
                          <MenuItem key={region} value={region}>
                            {formatMessage({
                              id: SUPPORT_REGION_LABEL[region],
                            })}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <AddressInputWithMap
                  mapHeight={400}
                  region={portalSettingData?.mapConfiguration?.region}
                  language={portalSetting?.defaultLanguage.toLowerCase()}
                  zoom={portalSettingData?.mapConfiguration?.zoomLevel}
                  selectedPosition={portalSettingData?.mapConfiguration?.centre}
                  formName={FORM_NAME}
                />
              </PreferencesWrapper>

              {userAccess?.userData.admin && (
                <>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'portal_edit.subheader.receiving_report_method',
                    })}
                  </SubSubHeader>
                  <PreferencesWrapper
                    padding={2}
                    width={'100%'}
                    direction="row"
                  >
                    <Controller
                      name="virtual"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <ReportSwitch
                          row
                          {...field}
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('virtual', e.target.value === 'true')
                          }}
                        >
                          <ReportMethod
                            value="false"
                            selected={!field.value ? 'selected' : ''}
                            control={<Radio />}
                            label={
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <VerifiedIcon
                                  color="success"
                                  fontSize="large"
                                />
                                <Stack>
                                  <Typography variant="subtitle2">
                                    {formatMessage({
                                      id: 'portal_edit.receiving_report_method.portal',
                                    })}
                                  </Typography>
                                  <Typography variant="body2">
                                    {formatMessage({
                                      id: 'portal_edit.receiving_report_method.portal.text',
                                    })}
                                  </Typography>
                                </Stack>
                              </Stack>
                            }
                          />
                          <ReportMethod
                            value="true"
                            selected={field.value ? 'selected' : ''}
                            control={<Radio />}
                            label={
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <EmailIcon color="warning" fontSize="large" />
                                <Stack>
                                  <Typography variant="subtitle2">
                                    {formatMessage({
                                      id: 'portal_edit.receiving_report_method.email',
                                    })}
                                  </Typography>
                                  <Typography variant="body2">
                                    {formatMessage({
                                      id: 'portal_edit.receiving_report_method.email.text',
                                    })}
                                  </Typography>
                                </Stack>
                              </Stack>
                            }
                          />
                        </ReportSwitch>
                      )}
                    />
                  </PreferencesWrapper>
                </>
              )}
            </Stack>

            {/* <Divider /> */}

            {/* <Stack paddingY={2} paddingX={4} spacing={2} width={'100%'}>
              <Typography variant="subtitle1">
                {formatMessage({
                  id: 'portal_item_edit.step.customer_profile',
                })}
              </Typography>

              <FormFieldsWrapper>
                <Stack direction="row" width="100%" paddingX={2}>
                  <InfoText width="350px">
                    {formatMessage({
                      id: 'portal_item_edit.step.customer_profile.label.fields',
                    })}
                  </InfoText>
                  <InfoText>
                    {formatMessage({
                      id: 'portal_item_edit.step.customer_profile.label.use_in_profile',
                    })}
                  </InfoText>
                </Stack>

                <ProfileFieldsWrapper>
                  {CUSTOMER_PROFILE_FIELDS.map((profileField, index) => (
                    <>
                      <Stack
                        direction="row"
                        width="100%"
                        key={profileField.name}
                        alignItems="center"
                        paddingY={1}
                      >
                        <Box width="350px">
                          <Typography variant="body2">
                            {formatMessage({
                              id: profileField.label,
                            })}
                          </Typography>
                        </Box>
                        <Box>
                          <Controller
                            name={`portalInfo.customerProfile.${profileField.name}`}
                            control={control}
                            // @ts-expect-error not error
                            defaultValue={profileField.value}
                            render={({ field }) => (
                              <ProfileFieldGroup
                                {...field}
                                disabled={profileField.disabled}
                                color="secondary"
                                exclusive
                                size="small"
                              >
                                <ToggleButton
                                  value={CustomerProfileInputType.REQUIRED}
                                >
                                  {formatMessage({
                                    id: 'portal_item_edit.step.customer_profile.button.required',
                                  })}
                                </ToggleButton>
                                <ToggleButton
                                  value={CustomerProfileInputType.OPTIONAL}
                                >
                                  {formatMessage({
                                    id: 'portal_item_edit.step.customer_profile.button.optional',
                                  })}
                                </ToggleButton>
                                <ToggleButton
                                  value={CustomerProfileInputType.HIDDEN}
                                >
                                  {formatMessage({
                                    id: 'portal_item_edit.step.customer_profile.button.hidden',
                                  })}
                                </ToggleButton>
                              </ProfileFieldGroup>
                            )}
                          />
                        </Box>
                      </Stack>
                      {index !== CUSTOMER_PROFILE_FIELDS.length - 1 && (
                        <Divider />
                      )}
                    </>
                  ))}
                </ProfileFieldsWrapper>
              </FormFieldsWrapper>
            </Stack> */}
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default PortalEditPage
