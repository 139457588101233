import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  type ReactNode,
} from 'react'
import { useIntl } from 'react-intl'
import { debounce } from '@mui/material/utils'
import Grid from '@mui/material/Grid2'
import Autocomplete from '@mui/material/Autocomplete'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { getGoogleMapsLoader } from 'components/form/mapLoader'
import { type PredictAddress } from 'types'

type AddressInputProps = TextFieldProps & {
  label?: string
  value?: string
  onChange: (value?: string) => void
  region?: string
  language?: string
  startAdornment?: ReactNode
}

const REGION = 'FI'
const DEFAULT_LANGUAGE = 'fi'

const AddressInput: React.FC<AddressInputProps> = ({
  label,
  value,
  onChange,
  region = REGION,
  language = DEFAULT_LANGUAGE,
  startAdornment,
  ...restProps
}) => {
  const { formatMessage } = useIntl()
  const mapServiceRef = useRef<google.maps.Map | null>(null)
  const autoCompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null)
  const [options, setOptions] = useState<PredictAddress[]>([])
  const [addressInputValue, setAddressInputValue] = useState('')

  const init = useCallback(async (): Promise<void> => {
    const gmapLoader = getGoogleMapsLoader({
      region,
      language,
    })

    const { AutocompleteService } = await gmapLoader.importLibrary('places')
    autoCompleteServiceRef.current = new AutocompleteService()
  }, [])
  const predictedValue = value
    ? {
        address: value,
        placeId: '',
      }
    : null

  useEffect(() => {
    void init()
    return () => {
      if (mapServiceRef.current) {
        google.maps.event.clearInstanceListeners(mapServiceRef.current)
      }
    }
  }, [])

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        if (autoCompleteServiceRef.current) {
          const { predictions } =
            await autoCompleteServiceRef.current.getPlacePredictions({
              input: search,
            })

          setOptions(
            predictions.map((predict) => ({
              address: predict.description,
              placeId: predict.place_id,
            })),
          )
        }
      }, 400),
    [],
  )

  useEffect(() => {
    if (addressInputValue) {
      void fetchOptions(addressInputValue)
    } else {
      setOptions([])
    }
  }, [addressInputValue])

  const renderOptions = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: PredictAddress,
    ): React.ReactNode => {
      return (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid sx={{ display: 'flex', width: 44 }}>
              <LocationOnIcon sx={{ color: 'text.secondary' }} />
            </Grid>
            <Grid
              sx={{
                width: 'calc(100% - 44px)',
                wordWrap: 'break-word',
              }}
            >
              {option.address}
            </Grid>
          </Grid>
        </li>
      )
    },
    [addressInputValue],
  )

  return (
    <Autocomplete
      value={predictedValue}
      autoComplete={true}
      selectOnFocus={true}
      options={options}
      renderOption={renderOptions}
      isOptionEqualToValue={(option, value) => option.address === value.address}
      getOptionLabel={(option) => option.address}
      noOptionsText={formatMessage({
        id: 'address_input.address_search_empty_result',
      })}
      onInputChange={(_, newInputValue) => {
        setAddressInputValue(newInputValue)
        onChange(newInputValue)
      }}
      onChange={(_, newValue) => {
        onChange(newValue?.address)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...restProps}
          value={addressInputValue}
          size="small"
          autoComplete="off"
          label={
            label ??
            formatMessage({
              id: 'address_input.placeholder.address',
            })
          }
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment,
          }}
          fullWidth={true}
          sx={{ borderRadius: '4px' }}
        />
      )}
    />
  )
}

export default AddressInput
