import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'

import { CategoryTag, UncategorizedTag } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import { type CaseCategory } from 'components/case/caseTypes'

type CategoryTagListProps = {
  category?: CaseCategory
}

const Wrapper = styled(Stack)`
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const CategoryTagList: React.FC<CategoryTagListProps> = ({ category }) => {
  const { formatMessage } = useIntl()
  const { extractCaseCategoryNames } = usePortalSetting()

  const categoryLabels = useMemo((): string[] => {
    if (!category) {
      return []
    }

    return extractCaseCategoryNames(category)
  }, [category])

  if (!category) {
    return null
  }

  return (
    <Wrapper direction={'row'} alignItems={'center'} data-testid="category-tag">
      {!category.uncategorized &&
        categoryLabels.map((categoryLabel) => (
          <CategoryTag key={categoryLabel} label={categoryLabel} size="small" />
        ))}

      {category.uncategorized &&
        categoryLabels.map((categoryLabel) => (
          <UncategorizedTag
            key={categoryLabel}
            label={formatMessage({
              id: 'category_tag_list.uncategorized',
            })}
            size="small"
          />
        ))}
    </Wrapper>
  )
}

export default CategoryTagList
