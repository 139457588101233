import {
  useEffect,
  useRef,
  useState,
  type MouseEvent,
  useMemo,
  useCallback,
} from 'react'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import OT, {
  type Publisher,
  type Session,
  type Subscriber,
} from '@opentok/client'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { nanoid } from 'nanoid'
import { useSnackbar } from 'notistack'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import styled from '@mui/material/styles/styled'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VideocamIcon from '@mui/icons-material/Videocam'
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import MicOffIcon from '@mui/icons-material/MicOff'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CheckIcon from '@mui/icons-material/Check'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'

import useRoute from '../hooks/useNavigate'
import DrawFocus, { type Position } from '../components/DrawFocus'
import {
  type VideoCallRoom,
  type Resource,
  type SessionData,
  type VideoDimensions,
} from 'types'
import useApi from 'hooks/useApi'
import {
  BackButtonGrey,
  ContentWrapper,
  InfoText,
  MainHeader,
  SecondaryButton,
  SubHeader,
} from 'components/StyledComponents'
import MediaPermissionsCheck from 'components/MediaPermissionsCheck'
import { FileType, Path, VideoCallStatus } from '../commonConstants'
import { extractFilenameFromUrl } from 'utils/fileUtils'
import ResourceGallery from 'components/resource/ResourceGallery'
import { portalSettingState } from 'state/portalSettingStates'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import { selectedResourceIndexState } from 'state/resourceStates'
import { CaseFileSource } from 'components/case/caseConstants'
import UserAvatar from 'components/user/UserAvatar'
import { type CaseInfo } from 'components/case/caseTypes'
import { userAccessState } from 'state/userStates'

const DEFAULT_VIDEO_DIMENSIONS = {
  width: 480,
  height: 640,
}

const ORIENTATION_LANDSCAPE = [0, 2]
const ORIENTATION_PORTRAIT = [1, 3]
const CAPTURE_IMAGE_DISABLE_TIME = 2000

const VideoCallWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 52, 1);
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CanvasWrapper = styled(Box)`
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
`

const UserWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 215px;
  background: #001f4d;
  color: #ffffff;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px 0px
    ${({ theme }) => 2 * theme.shape.borderRadius}px 0px;
  overflow: hidden;
`

const LoadingInfoWrapper = styled(Stack)`
  background: #001f4d;
  color: #ffffff;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const ConnectionMessage = styled(Stack)`
  color: #ffffff;
  border-radius: 0px ${({ theme }) => 2 * theme.shape.borderRadius}px
    ${({ theme }) => 2 * theme.shape.borderRadius}px 0px;
  align-items: center;
  margin-right: 20px;
  font-size: 14px;
  padding: 4px;
  gap: 10px;

  > svg {
    font-size: 10px;
  }
`

const NotConnectedMessage = styled(ConnectionMessage)`
  background: rgba(146, 160, 216, 1);
`

const ConnectingMessage = styled(ConnectionMessage)`
  background: rgba(255, 103, 136, 1);
`

const ConnectedMessage = styled(ConnectionMessage)`
  background: rgba(55, 146, 135, 1);
`

const MyVideoActionsWrapper = styled(Stack)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
`

const SubVideoActionsWrapper = styled(Stack)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
`

const MyVideoWrapper = styled(Box)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px 0px
    ${({ theme }) => 2 * theme.shape.borderRadius}px 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
`

const TakePictureButton = styled(SecondaryButton)`
  position: absolute;
  left: calc(50% - 65px);
  bottom: 20px;
  z-index: 1;
`

const PictureDescriptionWrapper = styled(Box)`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const ImageLoader = styled(Stack)`
  width: 100%;
  height: 120px;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.info.light};
  justify-content: center;
`

const UncheckedButton = styled(IconButton)`
  background: rgba(255, 255, 255, 0.3);

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  > svg {
    fill: white;
  }
`

const CheckedButton = styled(IconButton)`
  background: ${({ theme }) => theme.palette.background.paper};

  &:hover {
    background: white;
  }
`

const ImageComment = styled(TextField)(() => ({
  height: '120px',
  '& > .MuiInputBase-root': {
    height: '120px',
  },
}))

enum OT_SIGNAL {
  PHOTO_DO = 'photo_do',
  PHOTO_DONE = 'photo_done',
  PEN = 'pen',
  FOCUS = 'focus',
  VIDEO_CHANGED = 'video_changed',
  CAMERA_SWITCH = 'camera_switch',
}

const DUMMY_ID = 'dummy_user_id'
const MAX_WAITING_TIME = 60

const VideoCallPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { caseId } = useParams()
  const sessionRef = useRef<Session | null>(null)
  const publisherRef = useRef<Publisher | null>(null)
  const subscriberRef = useRef<Subscriber | null>(null)
  const subVideoEl = useRef<HTMLDivElement>(null)
  const pubVideoEl = useRef<HTMLDivElement>(null)
  const participantRef = useRef<number>(0)
  const [isSessionReady, setIsSessionReady] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [subVideoDimensions, setSubVideoDimensions] =
    useState<VideoDimensions | null>(null)
  const [pubVideoDimensions, setPubVideoDimensions] =
    useState<VideoDimensions | null>(null)
  const [focusPosition, setFocusPosition] = useState<Position | null>(null)
  const [isPublishVideo, setIsPublishVideo] = useState<boolean>(false)
  const [isPublishAudio, setIsPublishAudio] = useState<boolean>(true)
  const videoOrientationRef = useRef(0)
  const { sendGetRequest, sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const portalSetting = useRecoilValue(portalSettingState)
  const { data: caseFiles, mutate: mutateCaseFiles } = useSWR<Resource[]>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/resources`
      : null,
  )
  const { data: caseInfoData } = useSWR<CaseInfo>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}`
      : null,
  )
  const setSelectedResourceIndex = useSetRecoilState(selectedResourceIndexState)
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [currentRoomId, setCurrentRoomId] = useState<string | null>(null)
  const { data: videoCallRoomStatusData } = useSWR<VideoCallRoom>(
    currentRoomId
      ? `${
          process.env.REACT_APP_VIDEO_CALL_API_PATH ?? ''
        }/roomStatus/${currentRoomId}`
      : null,
    { refreshInterval: isConnecting ? 3000 : 0 },
  )
  const [waitingTime, setWaitingTime] = useState(0)
  const userAccess = useRecoilValue(userAccessState)
  const [processingPhotoIds, setProcessingPhotoIds] = useState<string[]>([])
  const [disabledCaptureButton, setDisabledCaptureButton] = useState(false)
  const analytics = getAnalytics()

  const videoCaptureResources = useMemo(
    () =>
      caseFiles?.filter(
        (resource: Resource) =>
          resource.source === CaseFileSource.VIDEO_CAPTURE,
      ),
    [caseFiles],
  )

  const sendPushMessage = useCallback(async (): Promise<void> => {
    if (
      currentRoomId &&
      caseInfoData?.reporter?.user?.lastDeviceToken &&
      sessionRef.current
    ) {
      try {
        const response = sendPostRequest(
          `${process.env.REACT_APP_VIDEO_CALL_API_PATH ?? ''}/push/call`,
          {
            roomId: currentRoomId,
            sessionId: sessionRef.current,
            token: caseInfoData?.reporter?.user?.lastDeviceToken,
            userName: `${userAccess?.userData.firstName} ${userAccess?.userData.lastName}`,
            portalCaseId: caseId,
            userId: userAccess?.userData.id,
          },
        )

        console.log((await response).json())
      } catch (error) {
        void disconnectSession()
        enqueueSnackbar(formatMessage({ id: 'general.error.unknown_error' }), {
          variant: 'error',
        })
      }
    }
  }, [currentRoomId, userAccess, caseInfoData, caseId])

  const calculateSubVideoWrapperDimensions = (
    videoDimensions: VideoDimensions | null,
  ): VideoDimensions => {
    if (subVideoEl.current && videoDimensions) {
      const ratio = videoDimensions.width / videoDimensions.height

      if (ratio > 1) {
        return {
          width: subVideoEl.current.clientWidth,
          height: subVideoEl.current.clientWidth / ratio,
        }
      }

      return {
        width: subVideoEl.current.clientHeight * ratio,
        height: subVideoEl.current.clientHeight,
      }
    }

    return DEFAULT_VIDEO_DIMENSIONS
  }

  const subVideoWrapperDimensions = useMemo(
    (): VideoDimensions =>
      calculateSubVideoWrapperDimensions(subVideoDimensions),
    [subVideoDimensions],
  )

  const handleScreenResize = (): void => {
    setSubVideoDimensions((prev) => calculateSubVideoWrapperDimensions(prev))
  }

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize)

    logEvent(analytics, 'web_video_call_page_view')

    return () => {
      window.removeEventListener('resize', handleScreenResize)
      void disconnectSession()
    }
  }, [])

  useEffect(() => {
    if (isSessionReady && currentRoomId) {
      void sendPushMessage()
    }
  }, [isSessionReady, currentRoomId])

  useEffect(() => {
    if (!isConnecting && currentRoomId) {
      setWaitingTime(0)
    } else if (currentRoomId && waitingTime < MAX_WAITING_TIME) {
      setTimeout(() => {
        setWaitingTime(waitingTime + 1)
      }, 1000)
    } else if (waitingTime >= MAX_WAITING_TIME) {
      void disconnectSession()
      enqueueSnackbar(
        formatMessage({ id: 'video_call.message.call_no_answer' }),
        {
          variant: 'error',
        },
      )
    }
  }, [currentRoomId, waitingTime, isConnecting])

  useEffect(() => {
    if (videoCallRoomStatusData?.status) {
      void disconnectSession()
      let message = formatMessage({ id: 'video_call.message.call_dropped' })
      if (videoCallRoomStatusData?.status === VideoCallStatus.DECLINED) {
        message = formatMessage({ id: 'video_call.message.call_declined' })
      }
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }
  }, [videoCallRoomStatusData])

  const handleError = (error: unknown): void => {
    console.error(error)
  }

  const disconnectSession = useCallback(async (): Promise<void> => {
    if (sessionRef.current != null && publisherRef.current != null) {
      sessionRef.current.unpublish(publisherRef.current)

      publisherRef.current.off()
      publisherRef.current.destroy()
    }

    if (sessionRef.current != null && subscriberRef.current != null) {
      sessionRef.current.unsubscribe(subscriberRef.current)
      subscriberRef.current.off()
    }

    if (sessionRef.current != null) {
      sessionRef.current.off()
      sessionRef.current.disconnect()
    }

    if (
      participantRef.current === 1 &&
      caseInfoData?.reporter?.user?.lastDeviceToken
    ) {
      try {
        await sendPostRequest(
          `${process.env.REACT_APP_VIDEO_CALL_API_PATH ?? ''}/push/missedCall`,
          {
            roomId: currentRoomId,
            sessionId: sessionRef.current,
            token: caseInfoData?.reporter?.user?.lastDeviceToken,
            userName: `${userAccess?.userData.firstName} ${userAccess?.userData.lastName}`,
            portalCaseId: caseId,
            userId: userAccess?.userData.id,
          },
        )
      } catch (error) {
        console.log(error)
        enqueueSnackbar(formatMessage({ id: 'general.error.unknown_error' }), {
          variant: 'error',
        })
      }
    }

    setIsSessionReady(false)
    setIsConnecting(false)
    setWaitingTime(0)

    sessionRef.current = null
    setCurrentRoomId(null)
    participantRef.current = 0
    setProcessingPhotoIds([])
  }, [currentRoomId, caseInfoData, userAccess, caseId])

  const setNewSubVideoDimensions = (videoDimensions: VideoDimensions): void => {
    console.log('new subVideoDimensions: ', subVideoDimensions, videoDimensions)
    setSubVideoDimensions(videoDimensions)

    if (videoDimensions.width > videoDimensions.height) {
      videoOrientationRef.current = ORIENTATION_LANDSCAPE[0]
    } else {
      videoOrientationRef.current = ORIENTATION_PORTRAIT[0]
    }
  }

  const initializeSession = useCallback(
    async (roomId: string) => {
      setIsConnecting(true)
      let response
      try {
        response = await sendGetRequest(
          `${process.env.REACT_APP_VIDEO_CALL_API_PATH ?? ''}/room/${roomId}`,
        )
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' })
        setIsConnecting(false)
      }

      if (response) {
        const { apiKey, sessionId, token }: SessionData = await response?.json()
        console.log(sessionId)
        if (apiKey && token && sessionId) {
          const session = OT.initSession(apiKey, sessionId)
          sessionRef.current = session

          session.on('streamCreated', function (event) {
            console.log(event.stream)
            setNewSubVideoDimensions(event.stream.videoDimensions)

            subscriberRef.current = session.subscribe(
              event.stream,
              'subscriber',
              {
                insertMode: 'append',
                width: '100%',
                height: '100%',
              },
              handleError,
            )

            subscriberRef.current.on('videoDimensionsChanged', (event) => {
              console.log('videoDimensionsChanged', event.newValue)
              setNewSubVideoDimensions(event.newValue)
            })
          })

          session.connect(token, function (error) {
            if (error != null) {
              handleError(error)
            } else {
              setIsSessionReady(true)
              setCurrentRoomId(roomId)
            }
          })

          session.on('connectionCreated', (event) => {
            participantRef.current++

            if (participantRef.current > 1) {
              setIsConnecting(false)
            }
          })

          session.on('streamDestroyed', (event) => {
            void disconnectSession()
            enqueueSnackbar(
              formatMessage({ id: 'video_call.message.caller_left' }),
              { variant: 'error' },
            )
          })

          session.on('signal', (event) => {
            console.log('signal', event)
            const signalType = event.type.split(':')[1]
            const { fromId } = JSON.parse(event.data ?? '')

            if (fromId !== DUMMY_ID) {
              switch (signalType as OT_SIGNAL) {
                case OT_SIGNAL.PHOTO_DONE: {
                  if (event.data && portalSetting && caseId) {
                    const { link, photoId } = JSON.parse(event.data)

                    sendPostRequest(
                      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                        portalSetting.id
                      }/cases/${caseId}/resources`,
                      {
                        uri: link,
                        name: extractFilenameFromUrl(link),
                        format: FileType.IMAGE,
                        source: CaseFileSource.VIDEO_CAPTURE,
                      },
                    )
                      .then(async (response) => {
                        const data = await response.json()
                        void mutateCaseFiles([...(caseFiles ?? []), data])
                        setProcessingPhotoIds((prev) =>
                          prev.filter((id) => id !== photoId),
                        )
                      })
                      .catch((error) => {
                        console.error(error)
                      })
                  }

                  break
                }

                case OT_SIGNAL.FOCUS: {
                  const subscriberDiv =
                    subVideoEl.current?.querySelector('#subscriber')
                  if (event.data && subscriberDiv) {
                    const { fromX, fromY, canvasWidth, canvasHeight } =
                      JSON.parse(event.data)
                    const ratioWidth = subscriberDiv.clientWidth / canvasWidth
                    const ratioHeight =
                      subscriberDiv.clientHeight / canvasHeight

                    setFocusPosition({
                      x: fromX * ratioWidth,
                      y: fromY * ratioHeight,
                    })
                  }

                  break
                }

                case OT_SIGNAL.VIDEO_CHANGED: {
                  console.log(event.data)
                  if (event.data) {
                    const { orientation } = JSON.parse(event.data)

                    if (
                      (ORIENTATION_LANDSCAPE.includes(
                        videoOrientationRef.current,
                      ) &&
                        ORIENTATION_PORTRAIT.includes(orientation)) ||
                      (ORIENTATION_PORTRAIT.includes(
                        videoOrientationRef.current,
                      ) &&
                        ORIENTATION_LANDSCAPE.includes(orientation))
                    ) {
                      setSubVideoDimensions((prev) => ({
                        width: prev?.height ?? DEFAULT_VIDEO_DIMENSIONS.width,
                        height: prev?.width ?? DEFAULT_VIDEO_DIMENSIONS.height,
                      }))
                    }
                    videoOrientationRef.current = orientation
                  }

                  break
                }

                default: {
                  console.log('signal method missing', event)
                }
              }
            }
          })
        } else {
          console.error('API key is missing')
          setIsConnecting(false)
        }
      }
    },
    [subVideoDimensions, caseId, caseInfoData, portalSetting],
  )

  useEffect(() => {
    if (isSessionReady && !isConnecting && sessionRef.current) {
      const publisher = OT.initPublisher(
        'publisher',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
          mirror: false,
          publishVideo: false,
        },
        handleError,
      )

      publisher.on('streamCreated', function (event) {
        setPubVideoDimensions(event.stream.videoDimensions)
      })

      publisherRef.current = publisher
      sessionRef.current.publish(publisher, handleError)
    }
  }, [isSessionReady, isConnecting])

  const handleVideoCall = (): void => {
    const roomId = nanoid(6)
    void initializeSession(roomId)
    logEvent(analytics, 'web_video_call_started')
  }

  const handleTakePicture = (): void => {
    if (sessionRef.current) {
      setDisabledCaptureButton(true)
      const newPhotoId = nanoid(10)
      setProcessingPhotoIds((prev) => [...prev, newPhotoId])
      sessionRef.current.signal(
        {
          type: OT_SIGNAL.PHOTO_DO,
          data: JSON.stringify({
            id: nanoid(10),
            photoId: newPhotoId,
            fromId: DUMMY_ID,
            platform: 'WebClient',
            mode: OT_SIGNAL.PHOTO_DO,
          }),
        },
        (error) => {
          if (error) {
            console.error(error)
          } else {
            console.log('Photo do')
          }
        },
      )
      setTimeout(() => {
        setDisabledCaptureButton(false)
      }, CAPTURE_IMAGE_DISABLE_TIME)
    }
  }

  const handleImageGalleryClose = (): void => {
    setIsImageGalleryOpen(false)
  }

  const openImageGallery = (index: number): void => {
    setIsImageGalleryOpen(true)
    setSelectedResourceIndex(index)
  }

  const handleSendFocus = (event: MouseEvent<HTMLDivElement>): void => {
    if (subVideoEl.current && sessionRef.current && subVideoDimensions) {
      const positionX =
        event.clientX -
        subVideoEl.current.offsetLeft -
        (subVideoEl.current.clientWidth - subVideoWrapperDimensions.width) / 2
      const positionY =
        event.clientY -
        subVideoEl.current.offsetTop -
        (subVideoEl.current.clientHeight - subVideoWrapperDimensions.height) / 2

      setFocusPosition({ x: positionX, y: positionY })
      const ratio = subVideoWrapperDimensions.width / subVideoDimensions.width
      console.log({ x: positionX, y: positionY })
      sessionRef.current.signal(
        {
          type: OT_SIGNAL.FOCUS,
          data: JSON.stringify({
            id: nanoid(10),
            fromId: DUMMY_ID,
            platform: 'WebClient',
            mode: OT_SIGNAL.FOCUS,
            mirrored: false,
            fromX: Math.round(positionX / ratio),
            fromY: Math.round(positionY / ratio),
            canvasWidth: subVideoDimensions.width,
            canvasHeight: subVideoDimensions.height,
          }),
        },
        (error) => {
          if (error) {
            console.error(error)
          } else {
            console.log('Focus')
            console.log('xy:', positionX, positionY)
            console.log(
              'calculateSubVideoDimensions:',
              subVideoWrapperDimensions,
            )
            console.log('subVideoDimensions:', subVideoDimensions)
          }
        },
      )
    }
  }

  const handleTurnOffCamera = (): void => {
    setIsPublishVideo(false)
    publisherRef.current?.publishVideo(false)
  }

  const handleTurnOnCamera = (): void => {
    setIsPublishVideo(true)
    publisherRef.current?.publishVideo(true)
  }

  const handleTurnOffAudio = (): void => {
    setIsPublishAudio(false)
    publisherRef.current?.publishAudio(false)
  }

  const handleTurnOnAudio = (): void => {
    setIsPublishAudio(true)
    publisherRef.current?.publishAudio(true)
  }

  const handleSwitchSubCamera = (): void => {
    if (sessionRef.current) {
      sessionRef.current.signal(
        {
          type: OT_SIGNAL.CAMERA_SWITCH,
          data: JSON.stringify({
            id: nanoid(10),
            fromId: DUMMY_ID,
            platform: 'WebClient',
            mode: OT_SIGNAL.CAMERA_SWITCH,
          }),
        },
        (error) => {
          if (error) {
            console.error(error)
          } else {
            console.log('CAMERA_SWITCH')
          }
        },
      )
    }
  }

  if (!caseId) {
    throw Error('caseId not found!')
  }

  const handleGoBackWithoutConfirm = useCallback((): void => {
    goTo(`${Path.CASES_LIST}/${caseId}`)
  }, [caseId])

  const handleGoBack = useCallback((): void => {
    if (isSessionReady && !isConnecting) {
      setIsConfirmDialogOpen(true)
    } else {
      handleGoBackWithoutConfirm()
    }
  }, [caseId, isSessionReady, isConnecting])

  const handleContinue = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const calculatePubVideoHeight = useMemo((): number => {
    if (pubVideoEl.current && pubVideoDimensions) {
      const ratio = pubVideoDimensions.width / pubVideoDimensions.height

      return pubVideoEl.current.clientWidth / ratio
    }

    return 200
  }, [pubVideoDimensions])

  const handleBlur = useCallback(
    async (
      event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
      caseFileId: string,
    ): Promise<void> => {
      try {
        if (portalSetting && caseId) {
          const inputValue = event.target.value
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}/resources/${caseFileId}`,
            {
              description: inputValue,
            },
          )
          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    [portalSetting, caseId],
  )

  return (
    <Grid container height={'100%'} columnSpacing={2} padding={2}>
      <Grid size={{ xs: 8 }} height={'100%'}>
        <Dialog open={isConfirmDialogOpen}>
          <DialogTitle>
            {formatMessage({ id: 'video_call.back_confirm_dialog.title' })}
          </DialogTitle>
          <DialogContent>
            {formatMessage({ id: 'video_call.back_confirm_dialog.content' })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGoBackWithoutConfirm}>
              {formatMessage({
                id: 'video_call.back_confirm_dialog.button.end',
              })}
            </Button>
            <Button onClick={handleContinue} autoFocus variant="contained">
              {formatMessage({
                id: 'video_call.back_confirm_dialog.button.continue',
              })}
            </Button>
          </DialogActions>
        </Dialog>

        <MediaPermissionsCheck />
        {videoCallRoomStatusData && (
          <Typography>{videoCallRoomStatusData?.status}</Typography>
        )}

        <ContentWrapper>
          <Box padding={2} height={'100%'}>
            <Stack height={'100%'}>
              <Stack
                direction={'row'}
                marginBottom={1}
                alignItems="center"
                spacing={2}
              >
                <BackButtonGrey
                  onClick={handleGoBack}
                  size="small"
                  aria-label={formatMessage({
                    id: 'general.icon_button.go_back',
                  })}
                >
                  <ArrowBackIcon />
                </BackButtonGrey>

                <MainHeader>
                  {formatMessage({ id: 'video_call.header' })}
                </MainHeader>
                {!isSessionReady && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isConnecting}
                    startIcon={<VideocamIcon />}
                    onClick={handleVideoCall}
                  >
                    {formatMessage({
                      id: 'video_call.button.start_video_call',
                    })}
                  </Button>
                )}
                {isSessionReady && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<PhoneDisabledIcon />}
                    onClick={(): void => {
                      void disconnectSession()
                    }}
                  >
                    {formatMessage({
                      id: 'video_call.button.cancel_video_call',
                    })}
                  </Button>
                )}
              </Stack>
              <VideoCallWrapper ref={subVideoEl} height={'100%'} width={'100%'}>
                <VideoWrapper
                  id="subscriber"
                  width={`${subVideoWrapperDimensions.width}px`}
                  height={`${subVideoWrapperDimensions.height}px`}
                >
                  {isConnecting && (
                    <LoadingInfoWrapper padding={2} spacing={2}>
                      <Stack direction="row" spacing={2}>
                        {isSessionReady ? (
                          <CheckIcon color="success" fontSize="small" />
                        ) : (
                          <CircularProgress color="error" size={20} />
                        )}

                        <Typography>
                          {formatMessage({
                            id: 'video_call.loading_info.sending_invitation',
                          })}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <CircularProgress
                          color="error"
                          size={20}
                          sx={{
                            visibility: isSessionReady ? 'visible' : 'hidden',
                          }}
                        />

                        <Typography
                          sx={{
                            color: !isSessionReady
                              ? 'grey.500'
                              : 'common.white',
                          }}
                        >
                          {formatMessage({
                            id: 'video_call.loading_info.connecting_call',
                          })}
                        </Typography>
                      </Stack>
                    </LoadingInfoWrapper>
                  )}
                </VideoWrapper>
                <UserWrapper paddingY={1}>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    paddingX={1}
                    paddingBottom={1}
                  >
                    <UserAvatar
                      avatarUrl={caseInfoData?.reporter?.user?.avatarUrl}
                      name={
                        caseInfoData?.reporter?.fullName ??
                        caseInfoData?.reporter?.user?.fullName
                      }
                      size={30}
                      email={caseInfoData?.reporter?.user?.email}
                      phone={caseInfoData?.reporter?.user?.phone}
                    />
                    <Box>
                      <Typography variant="body1">
                        {caseInfoData?.reporter?.fullName ??
                          caseInfoData?.reporter?.user?.fullName}
                      </Typography>
                      <InfoText>
                        {caseInfoData?.reporter?.user?.phone ??
                          caseInfoData?.reporter?.user?.email}
                      </InfoText>
                    </Box>
                  </Stack>

                  {!isSessionReady && !isConnecting && (
                    <NotConnectedMessage
                      paddingX={1}
                      flexDirection={'row'}
                      spacing={2}
                    >
                      <FiberManualRecordIcon />
                      {formatMessage({
                        id: 'video_call.status.not_connected',
                      })}
                    </NotConnectedMessage>
                  )}

                  {isConnecting && (
                    <ConnectingMessage
                      paddingX={2}
                      flexDirection={'row'}
                      spacing={2}
                    >
                      <FiberManualRecordIcon />
                      {formatMessage({
                        id: 'video_call.status.connecting',
                      })}
                    </ConnectingMessage>
                  )}

                  {isSessionReady && !isConnecting && (
                    <ConnectedMessage
                      paddingX={2}
                      flexDirection={'row'}
                      spacing={2}
                    >
                      <FiberManualRecordIcon />
                      {formatMessage({
                        id: 'video_call.status.streaming',
                      })}
                    </ConnectedMessage>
                  )}
                </UserWrapper>

                {isSessionReady && (
                  <>
                    <MyVideoWrapper
                      ref={pubVideoEl}
                      id="publisher"
                      height={`${calculatePubVideoHeight}px`}
                      width={'180px'}
                    />

                    {!isConnecting && (
                      <SubVideoActionsWrapper
                        margin={2}
                        direction={'row'}
                        spacing={2}
                      >
                        <UncheckedButton
                          onClick={handleSwitchSubCamera}
                          aria-label={formatMessage({
                            id: 'general.icon_button.switch_camera',
                          })}
                        >
                          <FlipCameraAndroidIcon />
                        </UncheckedButton>
                      </SubVideoActionsWrapper>
                    )}

                    <MyVideoActionsWrapper
                      margin={2}
                      direction={'row'}
                      spacing={2}
                    >
                      {!isPublishVideo && (
                        <UncheckedButton
                          onClick={handleTurnOnCamera}
                          aria-label={formatMessage({
                            id: 'general.icon_button.turn_on_camera',
                          })}
                        >
                          <VideoCameraFrontIcon />
                        </UncheckedButton>
                      )}

                      {isPublishVideo && (
                        <CheckedButton
                          onClick={handleTurnOffCamera}
                          aria-label={formatMessage({
                            id: 'general.icon_button.turn_off_camera',
                          })}
                        >
                          <VideoCameraFrontIcon
                            color="secondary"
                            fontSize="small"
                          />
                        </CheckedButton>
                      )}

                      {!isPublishAudio && (
                        <CheckedButton
                          onClick={handleTurnOnAudio}
                          aria-label={formatMessage({
                            id: 'general.icon_button.turn_on_audio',
                          })}
                        >
                          <MicOffIcon color="secondary" fontSize="small" />
                        </CheckedButton>
                      )}

                      {isPublishAudio && (
                        <UncheckedButton
                          onClick={handleTurnOffAudio}
                          aria-label={formatMessage({
                            id: 'general.icon_button.turn_off_audio',
                          })}
                        >
                          <MicOffIcon />
                        </UncheckedButton>
                      )}
                    </MyVideoActionsWrapper>

                    <TakePictureButton
                      variant="contained"
                      startIcon={<CameraAltIcon />}
                      size={'small'}
                      onClick={handleTakePicture}
                      loading={disabledCaptureButton}
                    >
                      {formatMessage({
                        id: 'video_call.button.take_picture',
                      })}
                    </TakePictureButton>
                  </>
                )}

                <CanvasWrapper
                  onMouseDown={handleSendFocus}
                  width={`${subVideoWrapperDimensions.width}px`}
                  height={`${subVideoWrapperDimensions.height}px`}
                >
                  {focusPosition && (
                    <DrawFocus
                      position={focusPosition}
                      width={subVideoWrapperDimensions.width}
                      height={subVideoWrapperDimensions.height}
                    />
                  )}
                </CanvasWrapper>
              </VideoCallWrapper>
            </Stack>
          </Box>
        </ContentWrapper>
      </Grid>
      <Grid size={{ xs: 4 }} height={'100%'}>
        <ContentWrapper height={'100%'}>
          <Stack padding={2} width={'100%'} height={'100%'}>
            <Stack flexGrow={1} spacing={2} overflow={'hidden'}>
              <Stack direction={'row'}>
                <SubHeader>
                  {formatMessage({
                    id: 'video_call.pictures.header',
                  })}{' '}
                  {!!videoCaptureResources?.length &&
                    videoCaptureResources.length}
                </SubHeader>
              </Stack>

              <Divider />
              <PictureDescriptionWrapper padding={1}>
                {formatMessage({
                  id: 'video_call.pictures.description',
                })}
              </PictureDescriptionWrapper>
              <Stack spacing={2} width="100%" overflow={'auto'} flexGrow={1}>
                {processingPhotoIds.map((processingPhotoId) => (
                  <ImageLoader key={processingPhotoId} padding={2}>
                    <LinearProgress />
                  </ImageLoader>
                ))}
                {videoCaptureResources?.map((caseFile, index) => (
                  <Stack
                    direction={'row'}
                    spacing={2}
                    key={caseFile.id}
                    width="100%"
                  >
                    <ResourceWrapper
                      url={caseFile.uri}
                      size={120}
                      onClick={() => {
                        openImageGallery(index)
                      }}
                      imageSize="cover"
                    />

                    <Box flexGrow={1}>
                      <ImageComment
                        placeholder={formatMessage({
                          id: 'video_call.pictures.add_description_placeholder',
                        })}
                        multiline={true}
                        fullWidth
                        rows={4}
                        size="small"
                        onBlur={(e) => {
                          void handleBlur(e, caseFile.id)
                        }}
                        inputProps={{ maxLength: 200 }}
                        defaultValue={caseFile.description}
                      />
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </ContentWrapper>

        <ResourceGallery
          resources={caseFiles ?? []}
          isOpen={isImageGalleryOpen}
          onClose={handleImageGalleryClose}
        />
      </Grid>
    </Grid>
  )
}

export default VideoCallPage
