import Box from '@mui/material/Box'

import SubCategoryFilterTree from './SubCategoryFilterTree'
import { FilterTreeView } from 'components/category/CategoryStyledComponents'
import { type CategoryBasic } from 'components/category/categoryTypes'

type CategoryFilterTreeProps = {
  categories: CategoryBasic[]
  selectedCategoryIds: string[]
  onCategoryChange: (categoryIds: string[], checked: boolean) => void
}

const CategoryFilterTree: React.FC<CategoryFilterTreeProps> = ({
  selectedCategoryIds,
  categories,
  onCategoryChange,
}) => {
  return (
    <FilterTreeView
      slots={{
        collapseIcon: Box,
        expandIcon: Box,
      }}
      expansionTrigger="iconContainer"
    >
      <SubCategoryFilterTree
        categories={categories}
        onCategoryChange={onCategoryChange}
        selectedCategoryIds={selectedCategoryIds}
      />
    </FilterTreeView>
  )
}

export default CategoryFilterTree
