import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import CircularProgress from '@mui/material/CircularProgress'
import {
  type GridColDef,
  useGridApiRef,
  GridRow,
  type GridRowProps,
  type GridFilterModel,
  type GridCellParams,
} from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import LaptopIcon from '@mui/icons-material/Laptop'
import PublicIcon from '@mui/icons-material/Language'
import CaseIcon from '@mui/icons-material/Assignment'
import TaskIcon from '@mui/icons-material/CheckCircle'
import FileIcon from '@mui/icons-material/FilePresent'
import CommentIcon from '@mui/icons-material/Comment'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import VideocamIcon from '@mui/icons-material/Videocam'
import EmailIcon from '@mui/icons-material/Email'

import { DataTable, InfoText } from 'components/StyledComponents'
import {
  type LogDiff,
  type CaseLog,
  type ParsedLogDiff,
} from 'components/log/logTypes'
import PreportiLogo from 'assets/icons/preporti_logo_small.svg'
import usePortalSetting from 'hooks/usePortalSetting'
import { shortenUuid } from 'utils/stringUtils'
import {
  CaseLogOperation,
  CaseLogReferenceType,
  Platform,
  TASK_FIELDS_LABELS,
  CASE_FIELDS_LABELS,
  FILE_FIELDS_LABELS,
  COMMENT_FIELDS_LABELS,
} from 'components/log/logConstants'
import {
  CASE_SHAREABILITY_LABEL,
  CASE_STATUS_LABEL,
  CASE_TASK_STATUS_LABEL,
  type Shareability,
  type CaseStatus,
  type CaseTaskStatus,
  FILE_SOURCE_LABEL,
  type CaseFileSource,
} from 'components/case/caseConstants'
import Chip from '@mui/material/Chip'
import { ForwardingArrowIcon } from 'components/icons/Icons'
import { type LocalizedString } from 'types'

type CaseLogListProps = {
  logs?: CaseLog[]
  isLoading: boolean
}

type ResourceLog = {
  id: string
  name: string
  source: CaseFileSource
}

export type CategoryLog = {
  key: string
  names: LocalizedString[]
  subcategory?: CategoryLog
}

const RowWrapper = styled(Stack)<{
  selected: boolean
}>`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 8px;
  background: ${({ selected, theme }) =>
    selected ? theme.palette.info.light : theme.palette.background.paper};
`

const MoreIcon = ExpandCircleDownOutlinedIcon

const LessIcon = styled(ExpandCircleDownOutlinedIcon)`
  transform: rotate(-180deg);
`

const CaseLogList: React.FC<CaseLogListProps> = ({ logs = [], isLoading }) => {
  const { formatMessage, locale } = useIntl()
  const dataTableRef = useGridApiRef()
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({})
  const { formatDate, getLocalizedContent } = usePortalSetting()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  })

  useEffect(() => {
    if (logs.length) {
      setExpandedRows({
        [logs[0].id]: true,
      })
    }
  }, [logs])

  const toggleExpanded = (id: string): void => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const getCategoryLogNames = (
    categoryLog: CategoryLog,
    language: string,
  ): string => {
    const names = [getLocalizedContent(categoryLog.names)]
    if (categoryLog.subcategory) {
      names.push(getCategoryLogNames(categoryLog.subcategory, language))
    }

    return names.filter(Boolean).join(' > ')
  }

  const columns: GridColDef[] = [
    {
      field: 'fold',
      headerName: '',
      width: 50,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderCell: (params) => (
        <IconButton
          aria-label={formatMessage({ id: 'general.icon_button.see_more' })}
          size="small"
          color="primary"
        >
          {params.row.isExpanded ? <LessIcon /> : <MoreIcon />}
        </IconButton>
      ),
    },
    {
      field: 'referenceId',
      headerName: formatMessage({ id: 'logs.header.object_id' }),
      width: 150,
      display: 'flex',
      valueGetter: (_, row) => shortenUuid(row.referenceId),
      renderCell: (params) => {
        let icon = null
        let label = ''

        if (params.row.referenceType === CaseLogReferenceType.CASE) {
          icon = <CaseIcon fontSize="inherit" />
          label = 'logs.reference_type.case'
        } else if (params.row.referenceType === CaseLogReferenceType.TASK) {
          icon = <TaskIcon fontSize="inherit" />
          label = 'logs.reference_type.task'
        } else if (params.row.referenceType === CaseLogReferenceType.FILE) {
          icon = <FileIcon fontSize="inherit" />
          label = 'logs.reference_type.file'
        } else if (params.row.referenceType === CaseLogReferenceType.COMMENT) {
          icon = <CommentIcon fontSize="inherit" />
          label = 'logs.reference_type.comment'
        } else {
          return null
        }

        return (
          <Stack direction="row" spacing={1}>
            <IconButton size="small" color="primary">
              {icon}
            </IconButton>
            <Stack>
              <Typography variant="body2">
                {formatMessage({
                  id: label,
                })}
              </Typography>
              <InfoText>ID {shortenUuid(params.row.referenceId)}</InfoText>
            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'operation',
      headerName: formatMessage({ id: 'logs.header.action' }),
      width: 120,
      display: 'flex',
      valueGetter: (_, row) => row.operationType,
      renderCell: (params) => {
        if (
          params.row.operationType === CaseLogOperation.ADD ||
          params.row.operationType === CaseLogOperation.FILE_UPLOAD
        ) {
          return (
            <Chip
              icon={<AddIcon color="primary" />}
              label={formatMessage({ id: 'logs.operation.added' })}
              size="small"
              sx={{
                background: '#61c2b733',
                fontSize: '12px',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        } else if (params.row.operationType === CaseLogOperation.EDIT) {
          return (
            <Chip
              icon={<EditIcon color="primary" />}
              label={formatMessage({ id: 'logs.operation.edited' })}
              size="small"
              sx={{
                background: '#FCEFD8',
                fontSize: '12px',
                color: '#041355',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        } else if (params.row.operationType === CaseLogOperation.DELETE) {
          return (
            <Chip
              icon={<ClearIcon color="primary" />}
              label={formatMessage({ id: 'logs.operation.deleted' })}
              size="small"
              sx={{
                background: '#ff85a069',
                fontSize: '12px',
                color: '#041355',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        } else if (params.row.operationType === CaseLogOperation.VIDEO_CALL) {
          return (
            <Chip
              icon={<VideocamIcon color="primary" />}
              label={formatMessage({ id: 'logs.operation.video_call' })}
              size="small"
              sx={{
                background: '#EAF4FF',
                fontSize: '12px',
                color: '#041355',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        } else if (params.row.operationType === CaseLogOperation.FORWARD) {
          return (
            <Chip
              icon={<ForwardingArrowIcon color="inherit" />}
              label={formatMessage({ id: 'logs.operation.case_forward' })}
              size="small"
              sx={{
                background: '#556E82',
                fontSize: '12px',
                color: 'white',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        } else if (
          params.row.operationType === CaseLogOperation.EMAIL_NOTIFICATION
        ) {
          return (
            <Chip
              icon={<EmailIcon color="inherit" />}
              label={formatMessage({ id: 'logs.operation.email_notification' })}
              size="small"
              sx={{
                fontSize: '12px',
                color: '#041355',
                '& .MuiSvgIcon-root': {
                  fontSize: 14,
                },
              }}
            />
          )
        }

        return null
      },
    },
    {
      field: 'created',
      headerName: formatMessage({ id: 'logs.header.created' }),
      width: 150,
      display: 'flex',
      type: 'date',
      valueGetter: (_, row) => new Date(row.created),
      renderCell: (params) => {
        const datetime = formatDate(params.row.created).split(' ')
        return (
          <Stack>
            <Typography variant="body2">{datetime[0]}</Typography>
            <InfoText>{datetime[1]}</InfoText>
          </Stack>
        )
      },
    },
    {
      field: 'platform',
      headerName: formatMessage({ id: 'logs.header.platform' }),
      width: 100,
      display: 'flex',
      valueGetter: (_, row) => row.platform,
      renderCell: (params) => {
        let icon = null
        let label = ''

        if (params.row.platform === Platform.WEB) {
          icon = <LaptopIcon fontSize="inherit" />
          label = 'logs.platform.web'
        } else if (params.row.platform === Platform.MOBILE) {
          icon = <SmartphoneIcon fontSize="inherit" />
          label = 'logs.platform.mobile'
        } else if (params.row.platform === Platform.PUBLIC_WEB) {
          icon = <PublicIcon fontSize="inherit" />
          label = 'logs.platform.public_web'
        } else if (params.row.platform === Platform.SYSTEM) {
          icon = <PreportiLogo />
          label = 'logs.platform.system'
        } else {
          return null
        }

        return (
          <Stack>
            <Tooltip
              title={formatMessage({
                id: label,
              })}
              arrow
            >
              <IconButton size="small" color="primary">
                {icon}
              </IconButton>
            </Tooltip>
          </Stack>
        )
      },
    },
    {
      field: 'user',
      headerName: formatMessage({ id: 'logs.header.user' }),
      flex: 1,
      display: 'flex',
      valueGetter: (_, row) =>
        row.platform === Platform.SYSTEM
          ? formatMessage({ id: 'logs.platform.system' })
          : row.author.fullName,
      renderCell: (params) =>
        params.row.platform === Platform.SYSTEM ? (
          <Typography variant="body2">
            {formatMessage({ id: 'logs.platform.system' })}
          </Typography>
        ) : (
          <Stack>
            <Typography variant="body2">
              {params.row.author.fullName}
            </Typography>
            <InfoText>{params.row.author.email}</InfoText>
          </Stack>
        ),
    },
  ]

  const parseCaseData = (changes: LogDiff[]): ParsedLogDiff[] => {
    const results: ParsedLogDiff[] = []
    const visibleKeys = [
      'shareability',
      'location_name',
      'location_latitude',
      'location_longitude',
      'location_postalCode',
      'location_country',
      'occurred',
      'description',
      'title',
      'status',
      'anonymous',
      'resources',
      'category',
      'forwarding_subject',
      'forwarding_body',
      'forwarding_to',
      'forwarding_cc',
      'forwarding_reporterEmail',
      'error',
      'logEventName',
      'email',
    ]

    changes
      .filter((change) => visibleKeys.includes(change.name))
      .forEach((change) => {
        const { name, oldValue, newValue } = change
        const translationKey = CASE_FIELDS_LABELS[name]

        if (translationKey) {
          const result: ParsedLogDiff = {
            name: formatMessage({ id: translationKey }),
          }

          if (name === 'occurred') {
            try {
              if (oldValue) {
                const testOldDate = new Date(oldValue)
                if (testOldDate.toString() !== 'Invalid Date') {
                  result.oldValue = formatDate(oldValue)
                }
              }

              if (newValue) {
                const testNewDate = new Date(newValue)
                if (testNewDate.toString() !== 'Invalid Date') {
                  result.newValue = formatDate(newValue)
                }
              }
            } catch (e) {
              console.log(e)
            }
          } else if (name === 'shareability') {
            if (oldValue) {
              result.oldValue = formatMessage({
                id: CASE_SHAREABILITY_LABEL[oldValue as Shareability],
              })
            }

            if (newValue) {
              result.newValue = formatMessage({
                id: CASE_SHAREABILITY_LABEL[newValue as Shareability],
              })
            }
          } else if (name === 'status') {
            if (oldValue) {
              result.oldValue = formatMessage({
                id: CASE_STATUS_LABEL[oldValue as CaseStatus],
              })
            }
            if (newValue) {
              result.newValue = formatMessage({
                id: CASE_STATUS_LABEL[newValue as CaseStatus],
              })
            }
          } else if (name === 'anonymous') {
            if (oldValue) {
              result.oldValue = formatMessage({
                id: result.oldValue === 'true' ? 'logs.yes' : 'logs.no',
              })
            }
            if (newValue) {
              result.newValue = formatMessage({
                id: result.newValue === 'true' ? 'logs.yes' : 'logs.no',
              })
            }
          } else if (name === 'resources') {
            if (oldValue) {
              const resources = JSON.parse(oldValue) as ResourceLog[]
              result.oldValue = resources.map((resource) => (
                <Typography key={resource.id} variant="body2">
                  [{shortenUuid(resource.id)}] {resource.name}
                </Typography>
              ))
            }
            if (newValue) {
              const resources = JSON.parse(newValue) as ResourceLog[]
              result.newValue = resources.map((resource) => (
                <Typography key={resource.id} variant="body2">
                  [{shortenUuid(resource.id)}] {resource.name}
                </Typography>
              ))
            }
          } else if (name === 'category') {
            if (oldValue) {
              try {
                const logCategories = JSON.parse(oldValue) as CategoryLog
                result.oldValue = getCategoryLogNames(
                  logCategories,
                  locale.toLowerCase(),
                )
              } catch (error) {
                result.oldValue = oldValue.split('|').join(' > ')
              }
            }
            if (newValue) {
              try {
                const logCategories = JSON.parse(newValue) as CategoryLog
                result.newValue = getCategoryLogNames(
                  logCategories,
                  locale.toLowerCase(),
                )
              } catch (error) {
                result.newValue = newValue.split('|').join(' > ')
              }
            }
          } else if (name === 'logEventName') {
            result.oldValue = getLogEventLabel(change.oldValue)
            result.newValue = getLogEventLabel(change.newValue)
          } else {
            result.oldValue = oldValue
            result.newValue = newValue
          }

          results.push(result)
        }
      })

    return results
  }

  const parseTaskData = (changes: LogDiff[]): ParsedLogDiff[] => {
    const results: ParsedLogDiff[] = []
    const visibleKeys = [
      'description',
      'deadline',
      'assigneeFullName',
      'assigneePartyName',
      'status',
      'taskAssignedEventType',
      'logEventName',
      'content',
      'filename',
    ]

    changes
      .filter((change) => visibleKeys.includes(change.name))
      .forEach((change) => {
        const { name, oldValue, newValue } = change
        const translationKey = TASK_FIELDS_LABELS[name]

        if (translationKey) {
          const result: ParsedLogDiff = {
            name: formatMessage({ id: translationKey }),
          }

          if (name === 'deadline') {
            try {
              if (oldValue) {
                const testOldDate = new Date(oldValue)
                if (testOldDate.toString() !== 'Invalid Date') {
                  result.oldValue = formatDate(oldValue)
                }
              }

              if (newValue) {
                const testNewDate = new Date(newValue)
                if (testNewDate.toString() !== 'Invalid Date') {
                  result.newValue = formatDate(newValue)
                }
              }
            } catch (e) {
              console.log(e)
            }
          } else if (name === 'assigneeFullName') {
            if (oldValue) {
              result.oldValue = oldValue
              const email = changes.filter(
                (change) => change.name === 'assigneeUserEmail',
              )[0].oldValue

              if (email) {
                result.oldValue = `${result.oldValue} (${email})`
              }
            }
            if (newValue) {
              result.newValue = newValue
              const email = changes.filter(
                (change) => change.name === 'assigneeUserEmail',
              )[0].newValue

              if (email) {
                result.newValue = `${result.newValue} (${email})`
              }
            }
          } else if (name === 'status') {
            if (oldValue) {
              result.oldValue = formatMessage({
                id: CASE_TASK_STATUS_LABEL[oldValue as CaseTaskStatus],
              })
            }
            if (newValue) {
              result.newValue = formatMessage({
                id: CASE_TASK_STATUS_LABEL[newValue as CaseTaskStatus],
              })
            }
          } else if (name === 'taskAssignedEventType') {
            if (
              newValue === 'TaskAssignedToTeamReassignedEvent' ||
              newValue === 'TaskAssignedToTeamUnclaimedEvent'
            ) {
              const assignee = changes.filter(
                (change) => change.name === 'assigneeUserId',
              )[0]
              console.log(assignee)
              if (!assignee) {
                result.newValue = formatMessage({
                  id: 'task_edit_form.option.no_assignee',
                })
              }
            }
          } else if (name === 'logEventName') {
            result.oldValue = getLogEventLabel(change.oldValue)
            result.newValue = getLogEventLabel(change.newValue)
          } else {
            result.oldValue = oldValue
            result.newValue = newValue
          }

          if (result.newValue || result.oldValue) {
            results.push(result)
          }
        }
      })

    return results
  }

  const parseFileData = (changes: LogDiff[]): ParsedLogDiff[] => {
    const results: ParsedLogDiff[] = []
    const visibleKeys = ['description', 'name', 'source']

    changes
      .filter((change) => visibleKeys.includes(change.name))
      .forEach((change) => {
        const { name, oldValue, newValue } = change
        const translationKey = FILE_FIELDS_LABELS[name]

        if (translationKey) {
          const result: ParsedLogDiff = {
            name: formatMessage({ id: translationKey }),
          }

          if (name === 'source') {
            if (oldValue) {
              result.oldValue = formatMessage({
                id: FILE_SOURCE_LABEL[oldValue as CaseFileSource],
              })
            }
            if (newValue) {
              result.newValue = formatMessage({
                id: FILE_SOURCE_LABEL[newValue as CaseFileSource],
              })
            }
          } else {
            result.oldValue = oldValue
            result.newValue = newValue
          }

          results.push(result)
        }
      })

    return results
  }

  const getLogEventLabel = (value?: string | null): string | null => {
    if (value === 'EmailToReporterCaseCreatedSentEvent') {
      return formatMessage({
        id: 'logs.email_notification.case_created',
      })
    } else if (value === 'EmailToReporterCaseCreatedFailedEvent') {
      return formatMessage({
        id: 'logs.email_notification.failed.case_created',
      })
    } else if (value === 'EmailToReporterCaseCommentedByMemberSentEvent') {
      return formatMessage({
        id: 'logs.email_notification.comment_added',
      })
    } else if (value === 'EmailToReporterCaseCommentedByMemberFailedEvent') {
      return formatMessage({
        id: 'logs.email_notification.failed.comment_added',
      })
    } else if (value === 'MemberCommentCreatedEvent') {
      return formatMessage({
        id: 'logs.comment.member_comment_created',
      })
    } else if (value === 'UserCommentCreatedEvent') {
      return formatMessage({
        id: 'logs.comment.user_comment_created',
      })
    } else if (value === 'TaskNoteCreatedEvent') {
      return formatMessage({
        id: 'logs.task.task_note_created',
      })
    }

    return null
  }

  const parseCommentData = (changes: LogDiff[]): ParsedLogDiff[] => {
    const results: ParsedLogDiff[] = []
    const visibleKeys = ['comment', 'logEventName', 'email']

    changes
      .filter((change) => visibleKeys.includes(change.name))
      .forEach((change) => {
        const translationKey = COMMENT_FIELDS_LABELS[change.name]
        if (translationKey) {
          if (change.name === 'logEventName') {
            results.push({
              name: formatMessage({ id: translationKey }),
              oldValue: getLogEventLabel(change.oldValue),
              newValue: getLogEventLabel(change.newValue),
            })
          } else {
            results.push({
              name: formatMessage({ id: translationKey }),
              oldValue: change.oldValue,
              newValue: change.newValue,
            })
          }
        }
      })

    return results
  }

  const parseRawData = (
    changes: LogDiff[],
    type: CaseLogReferenceType,
  ): ParsedLogDiff[] => {
    if (type === CaseLogReferenceType.CASE) {
      return parseCaseData(changes)
    } else if (type === CaseLogReferenceType.TASK) {
      return parseTaskData(changes)
    } else if (type === CaseLogReferenceType.FILE) {
      return parseFileData(changes)
    } else if (type === CaseLogReferenceType.COMMENT) {
      return parseCommentData(changes)
    }

    return []
  }

  const renderRow = (props: GridRowProps): JSX.Element => {
    const { row } = props
    const parsedChanges = parseRawData(row?.changes, row?.referenceType)

    return (
      <RowWrapper selected={row?.isExpanded}>
        <GridRow {...props} />

        {row?.isExpanded && (
          <Stack paddingX={2}>
            <Divider />
            <Stack width={'100%'} paddingY={2} paddingX={1} spacing={2}>
              {parsedChanges.map((change) => (
                <Stack key={change.name} direction="row" flex={1} spacing={1}>
                  <Typography variant="body2" width={120} color="#4C5DA6">
                    {change.name}
                  </Typography>
                  {change.oldValue && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          textDecoration: 'line-through',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {change.oldValue}
                      </Typography>
                      <Typography variant="body2">{' >> '}</Typography>
                    </>
                  )}
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {change.newValue}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </RowWrapper>
    )
  }

  const handleCellClick = (params: GridCellParams): void => {
    const { field, value } = params

    if (field === 'created' || field === 'fold') {
      return
    }

    setFilterModel({
      items: [{ field, operator: 'equals', value: String(value) }],
    })
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (logs.length === 0) {
    return null
  }

  return (
    <DataTable
      disableVirtualization
      apiRef={dataTableRef}
      rows={logs.map((log) => ({
        ...log,
        isExpanded: !!expandedRows[log.id],
      }))}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'created', sort: 'desc' }],
        },
      }}
      getRowHeight={() => 'auto'}
      hideFooter
      hideFooterSelectedRowCount
      onRowClick={(params) => {
        toggleExpanded(params.row.id)
      }}
      onCellClick={(params, event) => {
        if (params.field !== 'fold') {
          event.stopPropagation()
        }
        handleCellClick(params)
      }}
      filterModel={filterModel}
      onFilterModelChange={(model) => {
        setFilterModel(model)
      }}
      slots={{
        row: renderRow,
      }}
      slotProps={{
        row: {
          tabIndex: 0,
          onKeyDown: (event) => {
            if (event.key === 'Enter') {
              const row = event.target as HTMLTableRowElement
              row.click()
            }
          },
        },
      }}
      sx={{
        '& .MuiDataGrid-row': {
          border: 0,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        },
      }}
    />
  )
}

export default CaseLogList
