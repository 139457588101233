import { useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem'
import CollapseIcon from '@mui/icons-material/KeyboardArrowDown'
import ExpandIcon from '@mui/icons-material/KeyboardArrowUp'

import SubCategoryFilterTree from './SubCategoryFilterTree'
import usePortalSetting from 'hooks/usePortalSetting'
import { type CategoryBasic } from 'components/category/categoryTypes'

type CategoryFilterItemProps = {
  category: CategoryBasic
  selectedCategoryIds: string[]
  onCategoryChange: (ids: string[], checked: boolean) => void
}

const CategoryControlLabel = styled(FormControlLabel)`
  width: 100%;

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

const TreeItem = styled(MuiTreeItem)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.info.light : 'transparent'};
`

const CategoryFilterItem: React.FC<CategoryFilterItemProps> = ({
  category,
  selectedCategoryIds,
  onCategoryChange,
}) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLLIElement>(null)
  const { getLocalizedContent } = usePortalSetting()

  const findSubCategoriesIds = (categories: CategoryBasic[]): string[] => {
    const ids: string[] = []
    categories.forEach((category) => {
      if (!!category.subcategories && category.subcategories.length > 0) {
        ids.push(...findSubCategoriesIds(category.subcategories))
      } else {
        ids.push(category.id)
      }
    })
    return ids
  }

  const subcategoriesIds = useMemo(
    () => findSubCategoriesIds(category.subcategories ?? []),
    [category.subcategories],
  )

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      selected={
        selectedCategoryIds.includes(category.id) ||
        (!!category.subcategories &&
          category.subcategories.length > 0 &&
          subcategoriesIds.some((id) => selectedCategoryIds.includes(id)))
      }
      label={
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            <CategoryControlLabel
              control={
                <Checkbox
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (
                      !!category.subcategories &&
                      category.subcategories.length > 0
                    ) {
                      onCategoryChange(subcategoriesIds, event.target.checked)
                    } else {
                      onCategoryChange([category.id], event.target.checked)
                    }
                  }}
                  indeterminate={
                    !!category.subcategories &&
                    category.subcategories.length > 0 &&
                    subcategoriesIds.some((id) =>
                      selectedCategoryIds.includes(id),
                    ) &&
                    !subcategoriesIds.every((id) =>
                      selectedCategoryIds.includes(id),
                    )
                  }
                  checked={
                    selectedCategoryIds.includes(category.id) ||
                    (subcategoriesIds.every((id) =>
                      selectedCategoryIds.includes(id),
                    ) &&
                      !!category.subcategories &&
                      category.subcategories.length > 0)
                  }
                />
              }
              label={
                category.uncategorized
                  ? formatMessage({
                      id: 'category_tag_list.uncategorized',
                    })
                  : getLocalizedContent(category.names)
              }
            />
          </Box>
        </Stack>
      }
      slots={{
        collapseIcon:
          category.subcategories && category.subcategories.length > 0
            ? ExpandIcon
            : undefined,
        expandIcon:
          category.subcategories && category.subcategories.length > 0
            ? CollapseIcon
            : undefined,
      }}
    >
      <SubCategoryFilterTree
        categories={category.subcategories ?? []}
        selectedCategoryIds={selectedCategoryIds}
        onCategoryChange={onCategoryChange}
      />
    </TreeItem>
  )
}

export default CategoryFilterItem
