import { atom } from 'recoil'

import { type CaseInfo } from 'components/case/caseTypes'

const selectedCaseState = atom<CaseInfo | null>({
  key: 'selectedCaseState',
  default: null,
})

export { selectedCaseState }
