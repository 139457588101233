import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { type XYCoord, useDrag, useDrop } from 'react-dnd'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import DeleteIcon from '@mui/icons-material/Delete'
import HideIcon from '@mui/icons-material/VisibilityOffRounded'
import ShowIcon from '@mui/icons-material/RemoveRedEyeRounded'
import EditIcon from '@mui/icons-material/Edit'

import { type CategoryFormData } from '../categoryTypes'
import SubCategoryEditTree from './SubCategoryEditTree'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import { type LocalizedString } from 'types'

type CategoryEditItemProps = {
  index: number
  paths: string[]
  category: CategoryFormData
  onAddCategory: (paths: string[]) => void
  onToggleCategory: (paths: string[], active: boolean) => void
  onDeleteCategory: (paths: string[]) => void
  onEditCategory: (paths: string[], translations: LocalizedString[]) => void
  currentHoverItem: string | null
  setCurrentHoverItem: (currentHoverItem: string | null) => void
  onMoveCategory: (paths: string[], index: number, toIndex: number) => void
}

type Item = {
  id: string
  index: number
}

const CategoryEditItem: React.FC<CategoryEditItemProps> = ({
  index,
  paths,
  category,
  onAddCategory,
  onToggleCategory,
  onDeleteCategory,
  onEditCategory,
  currentHoverItem,
  setCurrentHoverItem,
  onMoveCategory,
}) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLLIElement>(null)
  const { getLocalizedContent } = usePortalSetting()
  const pathsString = paths.join('|')
  const clonePaths = [...paths]
  clonePaths.pop()

  const parentPathsString = `root-${clonePaths.join('|')}`

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: parentPathsString,
      item: { id: category.id, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop()
        console.log(item, didDrop)
      },
    }),
    [category, index],
  )

  const [, drop] = useDrop(
    () => ({
      accept: parentPathsString,
      hover(item: Item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        const clientOffset = monitor.getClientOffset()

        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        const parentPaths = [...paths]
        parentPaths.pop()
        onMoveCategory(parentPaths, dragIndex, hoverIndex)

        item.index = hoverIndex
      },
    }),
    [category, paths, onMoveCategory, index],
  )

  drag(drop(ref))

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      style={{ opacity }}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      label={
        <Stack
          direction="row"
          width="100%"
          minHeight={14}
          onMouseEnter={() => {
            setCurrentHoverItem(pathsString)
          }}
          onMouseLeave={() => {
            setCurrentHoverItem(null)
          }}
        >
          <Box flexGrow={1}>
            <Typography
              variant="body1"
              color={category.active ? 'inherit' : 'text.disabled'}
            >
              {getLocalizedContent(category.names)}
            </Typography>
          </Box>
          {currentHoverItem === pathsString && (
            <Stack direction={'row'} spacing={1}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  onEditCategory(paths, category.names)
                }}
                aria-label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  onToggleCategory(paths, !category.active)
                }}
                aria-label={formatMessage({
                  id: category.active
                    ? 'edit_categories.button.hide_category'
                    : 'edit_categories.button.show_category',
                })}
              >
                {category.active ? (
                  <HideIcon fontSize="inherit" />
                ) : (
                  <ShowIcon fontSize="inherit" />
                )}
              </IconButton>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  onDeleteCategory(paths)
                }}
                aria-label={formatMessage({
                  id: 'general.icon_button.delete',
                })}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      }
    >
      {paths.length < CATEGORY_MAX_DEPTH && (
        <SubCategoryEditTree
          paths={paths}
          categories={category.subcategories ?? []}
          onAddCategory={onAddCategory}
          onToggleCategory={onToggleCategory}
          onDeleteCategory={onDeleteCategory}
          onEditCategory={onEditCategory}
          currentHoverItem={currentHoverItem}
          setCurrentHoverItem={setCurrentHoverItem}
          onMoveCategory={onMoveCategory}
        />
      )}
    </TreeItem>
  )
}

export default CategoryEditItem
