import CategoryFilterItem from './CategoryFilterItem'
import { type CategoryBasic } from 'components/category/categoryTypes'

type SubCategoryFilterTreeProps = {
  categories: CategoryBasic[]
  selectedCategoryIds: string[]
  onCategoryChange: (ids: string[], checked: boolean) => void
}

const SubCategoryFilterTree: React.FC<SubCategoryFilterTreeProps> = ({
  categories,
  selectedCategoryIds,
  onCategoryChange,
}) => {
  return categories?.map((category, index) => (
    <CategoryFilterItem
      key={category.id}
      category={category}
      selectedCategoryIds={selectedCategoryIds}
      onCategoryChange={onCategoryChange}
    />
  ))
}

export default SubCategoryFilterTree
