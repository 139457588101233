import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import PostAddIcon from '@mui/icons-material/PostAdd'
import MenuIcon from '@mui/icons-material/Menu'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'

import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import {
  Path,
  PREPORTI_SUPPORT_LOCALES_MAP,
  SUPPORT_LOCALE_LABEL,
  ThemeMode,
} from '../../commonConstants'
import { type Locale } from 'types'
import useRoute from 'hooks/useNavigate'
import EportiLogo from 'assets/icons/eporti_white_logo.svg'
import PublicDownloadAppLinks from 'components/publicCase/PublicDownloadAppLinks'
import { themeModeState } from 'state/layoutStates'

type PublicTopBannerMobileProps = {
  onAddCase?: () => void
}

const TopBannerWrapper = styled(Box)`
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
`

const LogoWrapper = styled(Stack)`
  & img {
    cursor: pointer;
  }
`

const MenuDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    padding: ${({ theme }) => theme.spacing(2)};
    width: 260px;
  }

  .MuiLink-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: 500;
  }

  .MuiSelect-select {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`

const PortalTitle = styled('h1')`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

const PublicTopBannerMobile: React.FC<PublicTopBannerMobileProps> = ({
  onAddCase,
}) => {
  const { formatMessage, locale } = useIntl()
  const { goTo } = useRoute()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const [userLanguage, setUserLanguage] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)

  useEffect(() => {
    if (currentLocale) {
      setUserLanguage(currentLocale)
    }
  }, [currentLocale])

  const handleMenuOpen = (): void => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = (): void => {
    setIsMenuOpen(false)
  }

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('userLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleClickLogo = (): void => {
    goTo(Path.PUBLIC_CASE_LIST, true)
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper>
      <Grid container paddingX={2} paddingY={1}>
        <Grid flexGrow={1} alignSelf={'center'}>
          <LogoWrapper direction="row" spacing={2} alignItems="center">
            {publicPortalInfo?.logoUrl && (
              <img
                src={publicPortalInfo?.logoUrl}
                height="50px"
                onClick={handleClickLogo}
                alt={publicPortalInfo?.name}
              />
            )}
          </LogoWrapper>
        </Grid>
        <Grid textAlign={'right'} alignItems="center" display="flex">
          <Stack direction={'row'} spacing={2}>
            <IconButton
              sx={{ ml: 1 }}
              onClick={handleToggleTheme}
              color="inherit"
              aria-label={formatMessage({
                id:
                  themeMode === ThemeMode.DARK
                    ? 'top_banner.light_theme'
                    : 'top_banner.dark_theme',
              })}
            >
              {themeMode === ThemeMode.DARK ? (
                <LightThemeIcon />
              ) : (
                <DarkThemeIcon />
              )}
            </IconButton>

            {onAddCase && (
              <Button
                variant="contained"
                startIcon={<PostAddIcon />}
                size="small"
                onClick={onAddCase}
                color="secondary"
              >
                {formatMessage({ id: 'consumer_page.button.report' })}
              </Button>
            )}

            <IconButton
              color="inherit"
              aria-label={formatMessage({
                id: 'general.icon_button.main_menu_toggle',
              })}
              onClick={handleMenuOpen}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>

      <MenuDrawer
        anchor="right"
        open={isMenuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        <Stack spacing={1} height="100%">
          <Stack spacing={2} flexGrow={1}>
            <PortalTitle>{publicPortalInfo?.name}</PortalTitle>
            {publicPortalInfo &&
              publicPortalInfo.supportedLanguages.length > 1 && (
                <Select
                  value={userLanguage}
                  size="small"
                  inputProps={{
                    'aria-label': formatMessage({
                      id: 'general.select.language',
                    }),
                  }}
                >
                  {publicPortalInfo?.supportedLanguages.map((language) => (
                    <MenuItem
                      key={language}
                      value={language}
                      onClick={(): void => {
                        handleSelectLanguage(language)
                      }}
                    >
                      {SUPPORT_LOCALE_LABEL[language]}
                    </MenuItem>
                  ))}
                </Select>
              )}

            <Link
              href={`https://www.preporti.com/${
                PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
              }faq`}
              target="_blank"
            >
              {formatMessage({ id: 'consumer_footer.link.faq' })}
            </Link>
            <Link
              href={`https://www.preporti.com/${
                PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
              }terms-of-use`}
              target="_blank"
            >
              {formatMessage({ id: 'consumer_footer.link.terms' })}
            </Link>
            <Link
              href={`https://www.preporti.com/${
                PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
              }privacy-policy`}
              target="_blank"
            >
              {formatMessage({ id: 'consumer_footer.link.privacy_settings' })}
            </Link>
            <Link
              href={`https://www.preporti.com/${
                PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
              }`}
              target="_blank"
            >
              {formatMessage({ id: 'consumer_footer.link.about_us' })}
            </Link>
            <Typography variant="body1">Powered by Preporti</Typography>
          </Stack>

          <Stack spacing={1} alignItems="center" textAlign="center">
            <EportiLogo />

            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              {formatMessage({ id: 'public_case.text.preporti_desc' })}
            </Typography>

            <Stack
              flexGrow={1}
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
            >
              <PublicDownloadAppLinks />
            </Stack>
          </Stack>
        </Stack>
      </MenuDrawer>
    </TopBannerWrapper>
  )
}

export default PublicTopBannerMobile
