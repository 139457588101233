import { useRef } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

import { type Category } from '../categoryTypes'
import SubCategoryListTree from './SubCategoryListTree'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  CollapseIcon,
  ExpandIcon,
} from 'components/category/CategoryStyledComponents'

type CategoryListItemProps = {
  paths: string[]
  category: Category
}

const CategoryListItem: React.FC<CategoryListItemProps> = ({
  paths,
  category,
}) => {
  const ref = useRef<HTMLLIElement>(null)
  const { getLocalizedContent } = usePortalSetting()
  const clonePaths = [...paths]
  clonePaths.pop()

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      label={
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            <Typography
              variant="body1"
              color={category.active ? 'inherit' : 'text.disabled'}
            >
              {getLocalizedContent(category.names)}
            </Typography>
          </Box>
        </Stack>
      }
      slots={{
        collapseIcon:
          category.subcategories && category.subcategories.length > 0
            ? ExpandIcon
            : undefined,
        expandIcon:
          category.subcategories && category.subcategories.length > 0
            ? CollapseIcon
            : undefined,
      }}
    >
      {paths.length < CATEGORY_MAX_DEPTH && (
        <SubCategoryListTree
          paths={paths}
          categories={category.subcategories ?? []}
        />
      )}
    </TreeItem>
  )
}

export default CategoryListItem
