import { useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import PublicTopBannerMobile from 'components/publicCase/PublicTopBannerMobile'
import { type PublicCasesRawData } from 'components/publicCase/publicCaseTypes'
import { Path } from '../commonConstants'
import useRoute from 'hooks/useNavigate'
import { publicPortalInfoState } from 'state/portalSettingStates'
import PublicCaseListMapMobile from 'components/publicCase/PublicCaseListMapMobile'
import PublicAlerts from 'components/publicCase/PublicAlerts'

const PublicCaseListMobilePage: React.FC = () => {
  const { goTo } = useRoute()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')
  const { data: casesResponseData, isLoading } = useSWR<PublicCasesRawData>(
    portalId
      ? `${
          process.env.REACT_APP_PUBLIC_API_PATH ?? ''
        }/portals/${portalId}/cases`
      : null,
  )
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)

  const handleAddCase = (): void => {
    goTo(Path.PUBLIC_CASE_ADD, true)
  }

  return (
    <Box height={'100vh'} width="100%">
      <Stack height={'100%'} width="100%" overflow="hidden">
        <PublicAlerts alerts={[]} />

        <PublicTopBannerMobile onAddCase={handleAddCase} />
        <Box flexGrow={1} overflow="auto">
          {isLoading && <CircularProgress />}

          {publicPortalInfo && (
            <PublicCaseListMapMobile
              cases={casesResponseData?.cases ?? []}
              region={publicPortalInfo.mapConfiguration?.region}
              language={publicPortalInfo.defaultLanguage.toLowerCase()}
              zoom={publicPortalInfo.mapConfiguration?.zoomLevel}
              center={publicPortalInfo.mapConfiguration?.centre}
            />
          )}
        </Box>
      </Stack>
    </Box>
  )
}

export default PublicCaseListMobilePage
