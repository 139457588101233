import { useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import {
  Stack,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from '@mui/material'

import {
  hasUnsavedChangesState,
  isUnsavedConfirmDialogOpenState,
} from 'state/formStates'
import { networkErrorState } from 'state/errorStates'
import useApi from 'hooks/useApi'
import { type NetworkError } from 'types'
import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import { ERROR_CODE_MESSAGE } from 'errorConstants'
import useRoute from 'hooks/useNavigate'
import { Path, PortalType } from 'commonConstants'
import PublicCaseCreatedDialog from 'components/publicCase/PublicCaseCreatedDialog'

const AppPublicBootstrap: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const { goTo } = useRoute()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isUnsavedConfirmDialogOpen, setIsUnsavedConfirmDialogOpen] =
    useRecoilState(isUnsavedConfirmDialogOpenState)
  const networkError = useRecoilValue(networkErrorState)
  const { sendGetRequest } = useApi()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')

  const setCurrentLocale = useSetRecoilState(currentLocaleState)
  const [isPortalSettingLoading, setIsPortalSettingLoading] = useState(true)
  const setPublicPortalInfo = useSetRecoilState(publicPortalInfoState)
  const analytics = getAnalytics()
  const { pathname } = window.location

  const loadPortalSetting = async (portalId: string): Promise<void> => {
    try {
      setIsPortalSettingLoading(true)
      const response = await sendGetRequest(
        `${process.env.REACT_APP_PUBLIC_API_PATH ?? ''}/portals/${portalId}`,
      )
      const portalSettingData = await response?.json()
      setPublicPortalInfo(portalSettingData)

      const userLanguage = localStorage.getItem('userLanguage')
      const urlLang = searchParams.get('lang')
      let currentLocale =
        urlLang?.toUpperCase() ?? portalSettingData.defaultLanguage

      if (
        userLanguage &&
        portalSettingData.supportedLanguages.includes(userLanguage)
      ) {
        currentLocale = userLanguage
      }

      setCurrentLocale(currentLocale)
      document.title = portalSettingData.name

      if (pathname === '/public') {
        if (portalSettingData.type === PortalType.CITY) {
          goTo(Path.PUBLIC_CASE_LIST)
        } else {
          goTo(Path.PUBLIC_CASE_ADD)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsPortalSettingLoading(false)
    }
  }

  useEffect(() => {
    if (portalId) {
      logEvent(analytics, 'web_public_page_opened')
      void loadPortalSetting(portalId)
    }
  }, [portalId, analytics])

  useEffect(() => {
    if (networkError) {
      try {
        const jsonData = JSON.parse(networkError) as NetworkError
        let errorTranslationKey = 'general.error.unknown_error'
        if (jsonData.code && !!ERROR_CODE_MESSAGE[jsonData.code]) {
          errorTranslationKey = ERROR_CODE_MESSAGE[jsonData.code]
        }
        enqueueSnackbar(formatMessage({ id: errorTranslationKey }), {
          variant: 'error',
        })
      } catch (e) {
        enqueueSnackbar(networkError, { variant: 'error' })
      }
    }
  }, [networkError])

  const handleDiscard = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
    setHasUnsavedChanges(false)
  }

  const handleContinue = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
  }

  if (isPortalSettingLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <Dialog open={isUnsavedConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <Button
              fullWidth
              onClick={handleContinue}
              autoFocus
              variant="contained"
            >
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.continue',
              })}
            </Button>
            <Button fullWidth onClick={handleDiscard} variant="outlined">
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <PublicCaseCreatedDialog />

      <Outlet />
    </>
  )
}

export default AppPublicBootstrap
