import { type ChangeEvent, useState, forwardRef, useEffect } from 'react'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'

type MultiLineInputProps = TextFieldProps & {
  maxLength?: number
  rows: number
  helpMessage?: string
  startAdornment?: JSX.Element | string
}

const MultiLineInput = forwardRef<HTMLInputElement, MultiLineInputProps>(
  ({ maxLength, rows, error, helpMessage, startAdornment, ...props }, ref) => {
    const [charCount, setCharCount] = useState(0)

    useEffect(() => {
      if (props.value && typeof props.value === 'string') {
        setCharCount(props.value.length)
      }
    }, [props.value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const inputText = event.target.value

      setCharCount(inputText.length)

      if (props?.onChange) {
        props?.onChange(event)
      }
    }

    return (
      <>
        <TextField
          {...props}
          error={error}
          multiline
          onChange={handleChange}
          slotProps={{
            input: {
              inputComponent: TextareaAutosize,
              inputRef: ref,
              inputProps: {
                minRows: rows,
              },
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
            },
          }}
        />
        <Stack direction="row">
          <Box flexGrow={1}>
            <Typography variant="caption" color={error ? 'error' : grey[500]}>
              {helpMessage}
            </Typography>
          </Box>
          {maxLength && (
            <Box>
              <Typography
                align="right"
                variant="caption"
                color={charCount > maxLength ? 'error' : grey[500]}
              >
                {charCount}/{maxLength}
              </Typography>
            </Box>
          )}
        </Stack>
      </>
    )
  },
)

MultiLineInput.displayName = 'MultiLineInput'

export default MultiLineInput
