import { useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import PublicTopBanner from 'components/publicCase/PublicTopBanner'
import { type PublicCasesRawData } from 'components/publicCase/publicCaseTypes'
import { Path } from '../commonConstants'
import useRoute from 'hooks/useNavigate'
import PublicFooter from 'components/publicCase/PublicFooter'
import { publicPortalInfoState } from 'state/portalSettingStates'
import PublicCaseListMap from 'components/publicCase/PublicCaseListMap'
import PublicAlerts from 'components/publicCase/PublicAlerts'

const PublicCaseListPage: React.FC = () => {
  const { goTo } = useRoute()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')
  const {
    data: casesResponseData,
    isLoading,
    isValidating,
  } = useSWR<PublicCasesRawData>(
    portalId
      ? `${
          process.env.REACT_APP_PUBLIC_API_PATH ?? ''
        }/portals/${portalId}/cases`
      : null,
  )
  const [caseListWidth, setCaseListWidth] = useState(600)
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)

  useLayoutEffect(() => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    if (screenWidth < 1200) {
      setCaseListWidth(400)
    }
  }, [])

  const handleRefresh = (): void => {
    void mutate(
      `${
        process.env.REACT_APP_PUBLIC_API_PATH ?? ''
      }/portals/${portalId}/cases`,
    )
  }

  const handleAddCase = (): void => {
    goTo(Path.PUBLIC_CASE_ADD, true)
  }

  return (
    <Box height={'100vh'} width="100%">
      <Stack
        height={'100%'}
        width="100%"
        overflow="hidden"
        sx={{ position: 'relative' }}
      >
        <PublicAlerts alerts={[]} />

        <PublicTopBanner onAddCase={handleAddCase} />
        <Box flexGrow={1} overflow="auto" padding={2}>
          {publicPortalInfo && (
            <PublicCaseListMap
              caseListWidth={caseListWidth}
              isLoading={isLoading || isValidating}
              cases={
                casesResponseData?.cases?.sort(
                  (a, b) =>
                    new Date(b.updated).getTime() -
                    new Date(a.updated).getTime(),
                ) ?? []
              }
              onRefresh={handleRefresh}
              region={publicPortalInfo.mapConfiguration?.region}
              language={publicPortalInfo.defaultLanguage.toLowerCase()}
              zoom={publicPortalInfo.mapConfiguration?.zoomLevel}
              center={publicPortalInfo.mapConfiguration?.centre}
              onAddCase={handleAddCase}
            />
          )}
        </Box>
        <PublicFooter />
      </Stack>
    </Box>
  )
}

export default PublicCaseListPage
