export const WORKFLOW_NAME_MAX_LENGTH = 250
export const WORKFLOW_DESCRIPTION_MAX_LENGTH = 2000

export enum WorkflowCondition {
  ITEMS = 'ITEMS',
  CATEGORIES = 'CATEGORIES',
  CASE_SOURCES = 'CASE_SOURCES',
}

export const WORKFLOW_CONDITION_LABEL: Record<WorkflowCondition, string> = {
  [WorkflowCondition.ITEMS]: 'workflow.condition.items',
  [WorkflowCondition.CATEGORIES]: 'workflow.condition.categories',
  [WorkflowCondition.CASE_SOURCES]: 'workflow.condition.case_sources',
}

export enum WorkflowTrigger {
  CASE_CREATED = 'CASE_CREATED',
  CASE_STATUS_IN_PROGRESS = 'CASE_STATUS_IN_PROGRESS',
  CASE_STATUS_CANCELLED = 'CASE_STATUS_CANCELLED',
  TASK_DEADLINE_OVERDUE = 'TASK_DEADLINE_OVERDUE',
  TASK_NOTE_ADDED = 'TASK_NOTE_ADDED',
}

export enum WorkflowCaseSource {
  WEB_PORTAL = 'WEB_PORTAL',
  USER_REPORT = 'USER_REPORT',
  PUBLIC_WEB = 'PUBLIC_WEB',
}

export const WORKFLOW_CASE_SOURCE_LABEL: Record<WorkflowCaseSource, string> = {
  [WorkflowCaseSource.WEB_PORTAL]: 'workflow.case_source.web_portal',
  [WorkflowCaseSource.USER_REPORT]: 'workflow.case_source.user_report',
  [WorkflowCaseSource.PUBLIC_WEB]: 'workflow.case_source.public_web',
}

export enum WorkflowFormAction {
  ADD_TASK = 'ADD_TASK',
}

export const WORKFLOW_FORM_ACTION_LABEL: Record<WorkflowFormAction, string> = {
  [WorkflowFormAction.ADD_TASK]: 'workflow.action.add_task',
}

export const WORKFLOW_TRIGGER_LABEL: Record<WorkflowTrigger, string> = {
  [WorkflowTrigger.CASE_CREATED]: 'workflow.trigger.case_created',
  [WorkflowTrigger.CASE_STATUS_IN_PROGRESS]:
    'workflow.trigger.case_status_in_progress',
  [WorkflowTrigger.CASE_STATUS_CANCELLED]:
    'workflow.trigger.case_status_cancelled',
  [WorkflowTrigger.TASK_DEADLINE_OVERDUE]:
    'workflow.trigger.task_deadline_overdue',
  [WorkflowTrigger.TASK_NOTE_ADDED]: 'workflow.trigger.task_note_added',
}
