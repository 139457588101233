import { Navigate, useSearchParams } from 'react-router-dom'

type RedirectWithQueryProps = {
  path: string
}

type Parameter = {
  key: string
  value: string
}

const RedirectWithQuery: React.FC<RedirectWithQueryProps> = ({ path }) => {
  const [searchParams] = useSearchParams()
  const params: Parameter[] = []

  searchParams.forEach((value, key) => {
    params.push({
      key,
      value,
    })
  })

  return (
    <Navigate
      to={`${path}?${params
        .map(({ key, value }) => `${key}=${value}`)
        .join('&')}`}
      replace
    />
  )
}

export default RedirectWithQuery
